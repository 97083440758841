<template>
  <v-alert :value="true" type="error">
    <h2>{{ $t("application_error.looks_something_went_wrong") }}</h2>

    <p>{{ $t("application_error.we_track_these_errors_automatically_msg") }}</p>

    <h4>
      {{ $t("application_error.application_crashed_with_following_error_message") }}:
      <pre>{{ message }}</pre>

      <pre v-if="message.response && message.response.headers && message.response.headers['x-cloud-trace-context']">
        {{ $t("application_error.trace_id") }}: {{ message.response.headers['x-cloud-trace-context'] }}
      </pre>
    </h4>
    <br />
    <p v-if="reload">{{ $t("application_error.reloading_in", {num: countdown}) }}</p>

    <div v-if="reload">
      <a href="javascript:window.location.reload();" style="color: white">{{ $t("application_error.reload_now") }}</a>

      <a
        href="javascript:clearInterval(window.application_restart_interval);"
        style="color: white; margin-left: 50px"
        @click="cancel_reload"
      >{{ $t("application_error.cancel_reload") }}</a>
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: Error,
      required: true
    }
  },
  data() {
    return {
      reload: true,
      countdown: 10
    };
  },
  mounted() {
    this.set_reload_countdown();
  },
  methods: {
    cancel_reload() {
      this.reload = false;
      clearInterval(window.application_restart_interval);
    },
    set_reload_countdown() {
      let self = this;
      window.application_restart_interval = setInterval(function() {
        self.countdown--;
        if (self.countdown <= 0) {
          window.location.reload();
        }
      }, 1000);
    }
  }
};
</script>
