<template>
  <v-app>
    <div v-if="current_user.is_admin">
      <!-- Toolbar -->
      <v-toolbar dark flat style="max-height: 65px" class="page_toolbar">
        <v-toolbar-title>
          <v-icon left>fa-cogs</v-icon>Administration
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text to="/app/admin/clinics">
            <v-icon left>business</v-icon>Clinics
          </v-btn>
          <v-btn text to="/app/admin/revisions">
            <v-icon left>fa-database</v-icon>Revisions
          </v-btn>
          <v-btn text to="/app/admin/users">
            <v-icon left>account_circle</v-icon>Users
          </v-btn>
          <v-btn text to="/app/admin/charges">
            <v-icon left>attach_money</v-icon>Charges
          </v-btn>
          <v-btn text href="/api/assessment/49a09432-dc1d-41a0-ba3c-22b9cab94b5b/summary/report.csv">
            <v-icon left>assessment</v-icon>VA CSV
          </v-btn>
          <v-btn text href="/api/assessment/1fa92ee2-aec9-4330-9100-024bd8e0bf73/summary/report.csv">
            <v-icon left>assessment</v-icon>ASCI CSV
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <Revisions :initial_entity_id="entity_id" />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import Revisions from "../../shared/components/admin/Revisions";
import PermissionDenied from "../../shared/components/PermissionDenied";

export default {
  name: "App",
  components: {
    Revisions,
    PermissionDenied,
  },
  data() {
    return {
      entity_id: this.$route.params.id,
      current_user: this.$root.$children[0].current_user,
    };
  },
};
</script>
