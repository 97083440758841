<template>
  <v-app>
    <div v-if="current_clinic && current_clinic.id == clinic_id">
      <UserNewSubscription :current_clinic="current_clinic" />
    </div>
    <div v-else>Loading...</div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import UserNewSubscription from "../components/UserNewSubscription.vue";

export default {
  components: { UserNewSubscription },
  data() {
    return {
      clinic_id: this.$route.params.clinic_id,
    };
  },
  computed: {
    ...mapState(["current_clinic"]),
  },
  mounted() {
    if (!this.current_clinic || this.current_clinic.id != this.clinic_id) {
      this.$store.dispatch("loadCurrentClinic", { clinic_id: this.clinic_id });
    }
  },
};
</script>
