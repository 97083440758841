<template>
  <div v-if="current_clinic">
    <!-- Notifications -->
    <ClinicPageNotifications
      v-if="current_clinic && subpage == ''"
      :clinic_id="clinic_id"
      :num_users="my_users.length"
      :is_free="is_free"
      :role="clinic_role"
      @billing_click="billing_page"
    />

    <!-- Toolbar -->
    <v-toolbar color="secondary lighten-2" dark class="page_toolbar">
      <template v-if="clinic_role == 'user'">
        <v-toolbar-title style="cursor: pointer" @click="subpage = ''">
          <v-icon left>business</v-icon>
          <span>{{ current_clinic.title }}</span>
        </v-toolbar-title>
      </template>
      <v-spacer />
      <v-toolbar-items v-if="clinic_role != 'user'">
        <v-btn v-if="permission_edit_clinic" class="clinic_page_menu_button clinic_page_edit_info_button" small text @click="show_user_emails = true">
          <v-icon small left>email</v-icon>
          {{ $t("clinic_page.show_emails") }}
        </v-btn>
        <v-btn v-if="permission_edit_clinic" class="clinic_page_menu_button clinic_page_edit_info_button" small text @click="clinic_edit_dialog = true">
          <v-icon small left>edit</v-icon>
          {{ $t("clinic_page.edit_info") }}
        </v-btn>

        <v-btn
          v-if="permission_video_exercises && current_clinic && current_clinic.has_tag('public_video_exercises')"
          class="clinic_page_menu_button clinic_page_custom_exercises_button"
          small
          text
          @click="view_video_exercises"
        >
          <v-icon small left>video_library</v-icon>
          {{ $t("common.custom_exercises") }}
        </v-btn>

        <v-btn
          v-if="permission_view_grids && current_clinic && current_clinic.has_tag('public_grid_schedules') && current_clinic.has_tag('public_grid_sets')"
          class="clinic_page_menu_button"
          small
          text
          :disabled="subpage == 'grids'"
          @click="grids_page"
        >
          <v-icon small left>fas fa-border-all</v-icon>
          {{ $t("clinic_page.grids") }}
        </v-btn>

        <v-btn v-if="permission_invite_user" class="clinic_page_menu_button clinic_page_archived_users_button" small text @click="view_archived_users">
          <v-icon small left>delete</v-icon>
          {{ $t("common.archived_users") }}
        </v-btn>

        <v-btn v-if="permission_view_staff" class="clinic_page_menu_button clinic_page_staff_button" small text @click="staff_edit_dialog = true">
          <v-icon small left>fa-user-edit</v-icon>
          <span v-if="is_educational">Teachers and Students</span>
          <span v-else>{{ $t("common.staff") }}</span>
        </v-btn>

        <v-btn v-if="permission_view_billing" class="clinic_page_menu_button" small text :disabled="subpage == 'billing'" @click="billing_page">
          <v-icon small left>fas fa-money-check-alt</v-icon>
          {{ $t("clinic_page.billing") }}
        </v-btn>

        <v-btn v-if="permission_view_assessments" class="clinic_page_menu_button" small text :disabled="subpage == 'assessments'" @click="assessments_page">
          <v-icon small left>fa-clipboard-list</v-icon>
          {{ $t("common.assessments") }}
        </v-btn>

        <v-btn v-if="current_user.is_admin" class="clinic_page_menu_button" small text :href="'/app/admin/clinic/' + clinic_id" target="_blank">
          <v-icon small left>fas fa-tools</v-icon>
        </v-btn>
      </v-toolbar-items>
      <template v-if="clinic_role != 'user'" v-slot:extension>
        <v-layout justify-space-between>
          <template v-if="clinic_role != 'user'">
            <v-toolbar-title style="cursor: pointer" @click="subpage = ''">
              <v-icon left>business</v-icon>
              <span>{{ current_clinic.title }}</span>
            </v-toolbar-title>
            <v-spacer />
          </template>
          <div v-if="clinic_role == 'user' && $vuetify.breakpoint.name != 'xs'">
            <v-btn v-if="current_clinic.website" text :href="website_link" target="_blank">
              <v-icon left>link</v-icon>
              {{ website_view }}
            </v-btn>
            <v-btn v-if="current_clinic.phone" text :href="telephone_link">
              <v-icon left>phone</v-icon>
              {{ current_clinic.phone }}
            </v-btn>
            <v-btn v-if="physical_address" text :href="address_link" target="_blank">
              <v-icon left>location_on</v-icon>
              {{ physical_address }}
            </v-btn>
          </div>
        </v-layout>
      </template>
    </v-toolbar>

    <!-- Video Exercise List -->
    <v-dialog v-model="video_exercises_dialog" max-width="1200" scrollable @click:outside="load_video_exercises()">
      <ClinicPageManageVideos
        :video_exercises="video_exercises"
        :clinic_id="clinic_id"
        :clinic_title="current_clinic.title"
        @close="
          video_exercises_dialog = false;
          load_video_exercises();
        "
      ></ClinicPageManageVideos>
    </v-dialog>

    <!-- User Email List -->
    <v-dialog v-model="show_user_emails" max-width="1200" scrollable>
      <v-card>
        <v-toolbar>
          <v-btn icon @click="archived_users_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("clinic_page.show_emails") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text class="pa-12">
          <pre
            style="user-select: all"
          ><span v-for="(user, idx) in clinic_users" :key="idx"><template v-if="idx != 0">, </template>{{ user.name_given }} {{ user.name_family }} &lt;{{ user.email }}&gt;</span><span v-for="(invite, idx) in unaccepted_user_invites" :key="idx"><template v-if="clinic_users.length">, </template>{{ invite.name }} {{ invite.name_family }} &lt;{{ invite.email }}&gt;</span></pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Printables List -->
    <v-dialog v-model="printables_dialog" max-width="1200" scrollable>
      <ClinicPageManagePrintables :clinic_id="clinic_id" @close="printables_dialog = false"></ClinicPageManagePrintables>
    </v-dialog>

    <!-- Reading Texts List -->
    <v-dialog v-model="reading_texts_dialog" max-width="1200" scrollable>
      <ClinicPageManageTexts :clinic_id="clinic_id" @close="reading_texts_dialog = false"></ClinicPageManageTexts>
    </v-dialog>

    <!-- Archived Users List -->
    <v-dialog v-model="archived_users_dialog" max-width="1000" scrollable>
      <v-card>
        <v-toolbar>
          <v-btn icon @click="archived_users_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("common.archived_users") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-banner two-line style="margin-top: 20px">
            <p>
              {{ $t("clinic_page.archived_user_first_text") }}
              <v-icon x-small>fa fa-chart-bar</v-icon>
              &nbsp;{{ $t("clinic_page.for_archived_user") }}
              <v-icon small>restore_from_trash</v-icon>
              &nbsp;{{ $t("clinic_page.icon") }}
            </p>
          </v-banner>

          <v-text-field
            v-if="archived_users.length > 1"
            v-model="archived_user_search"
            append-icon="mdi-magnify"
            :label="$t('common.search')"
            single-line
            hide-details
            style="padding: 20px"
          ></v-text-field>

          <div v-if="archived_users && archived_users.length != 0">
            <v-list two-line subheader>
              <div v-for="(item, index) in archived_users" :key="index">
                <v-list-item v-if="filter_user_line(item, archived_user_search)">
                  <v-list-item-avatar>
                    <v-icon class="grey lighten-1 white--text">account_circle</v-icon>
                  </v-list-item-avatar>

                  <!-- Name and email -->
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name_given }} {{ item.name_family[0] }}</v-list-item-title>
                    <v-list-item-subtitle style="white-space: pre-line; max-height: 40px">{{ item.email }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- Archived Since -->

                  <!-- Actions -->
                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small outlined fab color="blue" style="margin-left: 16px" @click="archive_report_dialog_open(item.id)" v-on="on">
                          <v-icon>fa-chart-bar</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("clinic_page.view_archived_report") }}
                    </v-tooltip>
                  </v-list-item-action>

                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small outlined fab color="green" @click="unarchive_user(item)" v-on="on">
                          <v-icon>restore_from_trash</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("clinic_page.restore") }} {{ item.name_given }}
                      {{ item.name_family[0] }}
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>

                <v-dialog v-model="archive_report_dialogs[item.id]" scrollable max-width="1400">
                  <RegimenReport
                    v-if="exercises && video_exercises"
                    ref="archived_regimen_report"
                    :invite_id="item.invite_id"
                    :clinic_id="current_clinic.id"
                    :exercises="exercises"
                    :video_exercises="video_exercises"
                    @close="archive_report_dialogs[item.id] = false"
                  />
                </v-dialog>
              </div>
            </v-list>
          </div>
          <v-banner v-else style="margin-top: 20px; font-style: italic">
            {{ $t("clinic_page.no_archived_users_first") }}
            <v-icon small>delete</v-icon>
            &nbsp;{{ $t("clinic_page.no_archived_users_second") }}
          </v-banner>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit clinic dialog -->
    <v-dialog v-model="clinic_edit_dialog" max-width="1400" scrollable>
      <ClinicEdit
        :clinic="current_clinic"
        :admin_users="clinic_admin"
        :current_user="current_user"
        @close-clinic-edit="clinic_edit_dialog = false"
        @clinic-updated="load_clinic_info"
      />
    </v-dialog>

    <!-- Staff and Admin dialog -->
    <v-dialog v-model="staff_edit_dialog" max-width="1400">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="staff_edit_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="is_educational">Teachers and Students</span>
            <span v-else>{{ $t("common.staff") }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn v-if="permission_invite_staff" small color="primary" @click="create_staff_dialog = true">
              <span v-if="is_educational">Invite New Teacher or Student</span>
              <span v-else>{{ $t("clinic_page.invite_staff") }}</span>
            </v-btn>
            <v-dialog v-model="create_staff_dialog" max-width="1000">
              <InviteEdit
                v-if="staff_invite"
                resend_on_update
                :invite="staff_invite"
                :current_user="current_user"
                :clinic="current_clinic"
                :show_hipaa_notice="is_hipaa"
                :is_educational="is_educational"
                :allow_edit_billing_strategy="false"
                allow_user_role_edit
                @close-invite-edit="create_staff_dialog = false"
                @invite-created="load_clinic"
                @invite-updated="load_clinic"
              />
            </v-dialog>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-banner v-if="!is_educational" two-line style="margin-top: 20px">
            <p>{{ $t("clinic_page.staff_accounts_text") }}</p>
          </v-banner>
          <v-list v-if="clinic_staff && clinic_staff.length != 0">
            <v-list-item v-for="(item, index) in clinic_staff" :key="index">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1 white--text">account_circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.name_prefix }} {{ item.name_given }}
                  {{ item.name_family }}
                  {{ item.name_credentials }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <span v-if="is_educational">
                    <span v-if="item.user_role == 'admin'">Teacher</span>
                    <span v-else-if="item.user_role == 'staff'">Student</span>
                    <span v-else>{{ item.user_role }}</span>
                  </span>
                  <span v-else>{{ item.user_role }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content>{{ item.email }}</v-list-item-content>

              <v-list-item-action v-if="current_user.is_admin">
                <v-btn small outlined fab color="orange" style="margin-right: 16px" @click="masquerade_as_user(item)">
                  <v-icon>pageview</v-icon>
                </v-btn>
              </v-list-item-action>

              <v-list-item-action>
                <v-btn small outlined fab color="green" style="margin-right: 16px" @click="open_edit_staff_role(item.id)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  v-if="permission_invite_staff && item.user_id != current_user.id"
                  small
                  outlined
                  fab
                  color="red"
                  @click="delete_invite(item.invite_id, 'staff')"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-dialog v-model="edit_staff_role[item.id]" max-width="1000">
                <InviteRoleEdit
                  :invite_id="item.invite_id"
                  :current_role="item.user_role"
                  :current_user="current_user"
                  :is_educational="is_educational"
                  :nopatients="is_nopatients"
                  @close="close_edit_staff_role(item.id)"
                  @invite-role-updated="load_clinic"
                />
              </v-dialog>
            </v-list-item>
            <v-divider inset />
          </v-list>

          <!-- Unaccepted Staff Invites -->
          <div v-if="exercises && video_exercises && unaccepted_staff_invites && unaccepted_staff_invites.length != 0" style="width: 100%">
            <div v-for="(item, index) in unaccepted_staff_invites" :key="index">
              <ClinicPageInviteLine
                hide_assigned
                :invite="item"
                :current_user="current_user"
                :clinic="current_clinic"
                :exercises="exercises"
                :video_exercises="video_exercises"
                :clinic_staff="clinic_staff"
                :owner="ownership[item.id]"
                :current_user_role="clinic_role"
                :grid_sets="grid_sets"
                :grid_schedules="grid_schedules"
                allow_user_role_edit
                dense
                @delete_invite="delete_invite(item.id)"
                @invite_updated="load_clinic"
              ></ClinicPageInviteLine>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- User Regimen Or New User Subscription -->
    <div v-if="clinic_role == 'user'">
      <!-- User's subscription start-date is in the future -->
      <div v-if="current_clinic && current_user && user_subscription_future_start_date">
        <v-card>
          <div style="margin-top: 100px">
            <v-container>
              <v-layout align-center justify-space-around>
                <v-sheet elevation="2" style="padding: 50px; width: 800px">
                  <h2>{{ $t("clinic_page.start_date_title") }}</h2>
                  <p></p>
                  <p>
                    {{ $t("clinic_page.start_date_desc") }}
                    <strong>{{ user_subscription_future_start_date }}</strong>
                  </p>
                </v-sheet>
              </v-layout>
            </v-container>
          </div>
        </v-card>
      </div>

      <!-- User needs a subscription -->
      <div v-else-if="needs_user_subscription && current_clinic && current_user">
        <UserNewSubscription :current_clinic="current_clinic" />
      </div>

      <!-- User regimen -->
      <div v-else-if="exercises && video_exercises && current_clinic && current_user && current_progress_reports">
        <Regimen
          :invite_id="clinic_invite.id"
          :clinic="current_clinic"
          :user="current_user"
          :exercises="exercises"
          :video_exercises="video_exercises"
          title="This week's Exercises"
        >
          <template v-slot:all_done>
            <div
              style="font-size: x-large"
              class="ma-12"
              v-if="user_is_finished_grid_schedule && next_subscripion_type_on_all_done && next_grid_schedule_on_all_done && user_regimen.grid_schedule"
            >
              Congratulations on completing <strong>{{ user_regimen.grid_schedule.title }}</strong> of the VisionX Sports training program! The journey doesn’t
              stop here. Keep the momentum going, challenge yourself, and take your performance to the next level with
              <strong>{{ next_grid_schedule_on_all_done.title }}</strong
              >.

              <v-btn class="ml-12 mt-12" large color="red" :href="'/app/new_subscription/' + current_clinic.id">
                CONTINUE TO {{ next_grid_schedule_on_all_done.title }}
              </v-btn>
            </div>
          </template>
        </Regimen>

        <div v-if="show_history">
          <v-divider color="white" />

          <v-toolbar grey flat>
            <v-toolbar-title>
              <span>Past Exercises</span>
            </v-toolbar-title>
          </v-toolbar>

          <GridHistory />
        </div>
      </div>
    </div>

    <!-- Billing -->
    <div v-else-if="subpage == 'billing'">
      <v-toolbar grey>
        <v-btn icon @click="subpage = ''">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("clinic_page.billing") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <ClinicBilling
        ref="clinic_billing"
        :current_user="current_user"
        :current_user_role="clinic_role"
        :clinic="current_clinic"
        :clinic_admins="clinic_admin"
        @clinic-updated="load_clinic_info"
      />
    </div>

    <!-- Assessments -->
    <div v-else-if="subpage == 'assessments'">
      <v-toolbar grey>
        <v-btn icon @click="subpage = ''">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.assessments") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <ClinicAssessments ref="clinic_assessments" :clinic="current_clinic" />
    </div>

    <!-- Grids -->
    <div v-else-if="subpage == 'grids'">
      <v-toolbar grey>
        <v-btn icon @click="subpage = ''">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("clinic_page.grids") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <ClinicGrids
        ref="clinic_grids"
        :current_user="current_user"
        :current_user_role="clinic_role"
        :clinic="current_clinic"
        :exercises="exercises"
        :video_exercises="video_exercises"
        :grid_sets="grid_sets"
        :grid_schedules="grid_schedules"
        :show_id="current_user.is_admin"
        :show_intro_video="false"
        @grid-set-created="load_grids"
        @grid-set-updated="load_grids"
        @grid-set-deleted="load_grids"
        @grid-schedule-created="load_grids"
        @grid-schedule-updated="load_grids"
        @grid-schedule-deleted="load_grids"
      />
    </div>

    <!-- Educational -->
    <div v-else-if="is_nopatients">
      <ExerciseDemoList :exercises="exercises" :current_user="current_user" />
    </div>

    <!-- User list section -->
    <div v-else>
      <!-- Users toolbar -->
      <v-toolbar flat style="border-bottom: 1px solid lightgray">
        <v-toolbar-title>{{ $t("common.patients") }}</v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-if="clinic_users.length + unaccepted_user_invites.length > 1"
          v-model="user_search"
          append-icon="mdi-magnify"
          :label="$t('common.search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer />
        <v-toolbar-items>
          <v-btn v-if="permission_invite_user" class="clinic_page_invite_patient_button" text color="primary lighten-2" @click="create_invite_dialog = true">
            <v-icon left>mdi-account-plus</v-icon>
            {{ $t("clinic_page.invite_patient") }}
          </v-btn>
          <v-dialog v-model="create_invite_dialog" max-width="1000">
            <InviteEdit
              v-if="default_invite"
              resend_on_update
              :invite="default_invite"
              :current_user="current_user"
              :check_trial_expired="true"
              :current_user_role="clinic_role"
              :allow_edit_send_email="true"
              :default_send_email="false"
              :clinic="current_clinic"
              :allow_edit_billing_strategy="false"
              @billing_click="billing_page"
              @close-invite-edit="create_invite_dialog = false"
              @invite-created="load_clinic"
              @invite-updated="load_clinic"
            />
          </v-dialog>
        </v-toolbar-items>
      </v-toolbar>

      <!-- Users List -->
      <div v-if="exercises && video_exercises && ((my_users && my_users.length != 0) || (my_user_invites && my_user_invites.length != 0))">
        <div style="width: 100%">
          <div v-for="(item, index) in my_users" :key="index">
            <ClinicPageUserLine
              v-if="filter_user_line(item, user_search)"
              :user="item"
              :subscription="item.subscription"
              :current_user="current_user"
              :clinic="current_clinic"
              :exercises="exercises"
              :video_exercises="video_exercises"
              :clinic_staff="clinic_staff"
              :owner="ownership[item.id]"
              :activity_summary="activity_summary[item.id]"
              :latest_activity="latest_activity[item.invite_id]"
              :show_days_since="latest_activity_loaded"
              :grid_sets="grid_sets"
              :grid_schedules="grid_schedules"
              :show_assignment="false"
              :show_user_edit="permission_edit_user"
              :show_regimen_edit="permission_edit_user"
              :show_masquerade="permission_edit_user"
              :clinic_user_info_survey="clinic_user_info_survey"
              dense
              @archive_user="archive_user(item)"
              @masquerade_as_user="masquerade_as_user(item)"
              @regimen_created="load_clinic_users(assign_ownership)"
              @regimen_updated="load_clinic_users(assign_ownership)"
            >
              <template v-slot:action>
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small outlined fab tile color="purple" class="invite_line_action_button" style="margin-top: 7px; margin-left: 5px" v-on="on">
                          <v-icon>fa-clipboard-list</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="(assessment, index) in assessment_options" :key="index">
                          <v-btn :href="'/app/assessment/' + assessment.id + '/' + current_clinic.id + '/' + item.invite_id">{{ assessment.title }}</v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  Run Assessment
                </v-tooltip>
              </template>
            </ClinicPageUserLine>
          </div>
        </div>

        <!-- User Invites -->
        <div style="width: 100%">
          <div v-for="(item, index) in my_user_invites" :key="index">
            <ClinicPageInviteLine
              v-if="filter_invite_line(item)"
              :invite="item"
              :current_user="current_user"
              :clinic="current_clinic"
              :exercises="exercises"
              :video_exercises="video_exercises"
              :clinic_staff="clinic_staff"
              :owner="ownership[item.id]"
              :current_user_role="clinic_role"
              :grid_sets="grid_sets"
              :grid_schedules="grid_schedules"
              :show_assignment="false"
              :show_regimen_edit="false"
              :invite_edit_allow_edit_send_email="true"
              :invite_edit_default_send_email="false"
              :invite_edit_resend_on_update="false"
              dense
              @delete_invite="delete_invite(item.id)"
              @invite_updated="load_clinic"
              @regimen_created="load_clinic_users(assign_ownership)"
              @regimen_updated="load_clinic_users(assign_ownership)"
            >
              <template v-slot:action>
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small outlined fab tile color="purple" class="invite_line_action_button" style="margin-top: 7px; margin-left: 5px" v-on="on">
                          <v-icon>fa-clipboard-list</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="(assessment, index) in assessment_options" :key="index">
                          <v-btn :href="'/app/assessment/' + assessment.id + '/' + current_clinic.id + '/' + item.id">{{ assessment.title }}</v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  Run Assessment
                </v-tooltip>
              </template>
            </ClinicPageInviteLine>
          </div>
        </div>
      </div>

      <!-- Some Space at the bottom for the helpscout overlay -->
      <div style="height: 40px" />

      <!-- Welcome box -->
      <div v-if="loaded && my_user_invites && my_user_invites.length == 0 && my_users && my_users.length == 0" style="margin-top: 30px">
        <v-container>
          <v-layout align-center justify-space-around>
            <v-sheet elevation="2" style="padding: 50px; width: 800px">
              <h2>{{ $t("clinic_page.welcome_neurovisual_trainer") }}</h2>
              <p></p>
              <p>{{ $t("clinic_page.welcome_first_text") }}</p>
            </v-sheet>
          </v-layout>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

import InviteRoleEdit from "../../shared/components/InviteRoleEdit";
import ClinicEdit from "../../shared/components/ClinicEdit";
import ClinicPageInviteLine from "../../shared/components/ClinicPageInviteLine";
import ClinicPageManageTexts from "../../shared/components/ClinicPageManageTexts";
import ClinicPageManageVideos from "../../shared/components/ClinicPageManageVideos";
import ClinicPageManagePrintables from "../../shared/components/ClinicPageManagePrintables";
import ClinicPageUserLine from "../../shared/components/ClinicPageUserLine";
import ClinicPageNotifications from "../../shared/components/ClinicPageNotifications";
import ClinicBilling from "../../shared/components/ClinicBilling";
import ClinicAssessments from "./ClinicAssessments";
import ClinicGrids from "../../shared/components/ClinicGrids";
import InviteEdit from "../../shared/components/InviteEdit";
import Regimen from "../../shared/components/Regimen";
import GridHistory from "./GridHistory";
import RegimenReport from "../../shared/components/RegimenReport";
import ExerciseDemoList from "../../shared/components/ExerciseDemoList";
import UserNewSubscription from "./UserNewSubscription";
import { mapState } from "vuex";
import { logout_helpscout } from "../../shared/lib/helpscout";

import subscription_types from "../../../data/subscriptions.yml";

export default {
  components: {
    ClinicEdit,
    ClinicPageInviteLine,
    ClinicPageManageTexts,
    ClinicPageManageVideos,
    ClinicPageManagePrintables,
    ClinicPageUserLine,
    ClinicAssessments,
    InviteEdit,
    InviteRoleEdit,
    Regimen,
    RegimenReport,
    ClinicBilling,
    ClinicPageNotifications,
    ExerciseDemoList,
    ClinicGrids,
    UserNewSubscription,
    GridHistory,
  },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
    user_invites: {
      type: Array,
      required: true,
    },
    route_subpage: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      loaded: false,
      subpage: this.route_subpage,
      activity_summary: {},
      latest_activity: {},
      latest_activity_loaded: false,
      all_users: [],
      archive_report_dialogs: {},
      archived_users: [],
      archived_users_dialog: false,
      clinic_admin: [],
      clinic_edit_dialog: false,
      clinic_staff: [],
      clinic_users: [],
      create_invite_dialog: false,
      create_staff_dialog: false,
      exercises: null,
      video_exercises: null,
      my_user_invites: [],
      my_users: [],
      ownership: {},
      reading_texts_dialog: false,
      rows_per_page: [-1],
      edit_staff_role: {},
      staff_edit_dialog: false,
      unaccepted_staff_invites: [],
      unaccepted_user_invites: [],
      video_exercises_dialog: false,
      printables_dialog: false,
      clinic_notifications: null,
      grid_sets: [],
      grid_schedules: [],
      user_search: "",
      archived_user_search: "",
      show_user_emails: false,
      clinic_user_info_survey: {
        items: [
          {
            id: "underage",
            title: "Under Age",
            type: "switch",
            props: {
              label: "Underage",
            },
            instructions: "Is the user under 18 years old?",
          },
          {
            id: "player_email",
            title: "Player Email",
            type: "text-field",
            props: {},
            visible: "underage == true",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState([
      "current_clinic",
      "user_subscriptions",
      "user_regimens",
      "user_metadata",
      "free_trial_grid_schedules",
      "app_config",
      "current_progress_reports",
    ]),
    assessment_options() {
      let options = [];

      for (let grid_set_id of Object.keys(this.app_config.assessments)) {
        let grid_set = this.grid_sets.find((gs) => gs.id == grid_set_id);
        if (grid_set) {
          options.push({
            id: grid_set_id,
            title: grid_set.title,
          });
        }
      }

      return options;
    },
    is_ipad_sized() {
      let matched = window.matchMedia("(min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape)");
      return matched.matches;
    },
    clinic_invite() {
      if (this.user_invites) {
        for (var i in this.user_invites) {
          let invite = this.user_invites[i];
          if (invite.clinic_id == this.clinic_id) {
            return invite;
          }
        }
      }
      return null;
    },
    clinic_role() {
      if (this.current_user && this.current_user.is_admin) {
        return "admin";
      }
      if (this.clinic_invite) {
        return this.clinic_invite.user_role;
      }
      return "";
    },
    next_grid_schedule_on_all_done() {
      let next_sub = this.next_subscripion_type_on_all_done;
      if (!next_sub) {
        return null;
      }
      let next_grid_schedule_id = null;

      if (next_sub.default_grid_schedule) {
        next_grid_schedule_id = next_sub.default_grid_schedule;
      } else if (next_sub.grid_schedule_series) {
        if (!this.user_regimen || !this.user_regimen.grid_schedule) {
          return null;
        }

        let current_grid_schedule_id = this.user_regimen.grid_schedule.id;
        // Find the next grid schedule in the series, that comes after the current grid schedule
        // If the current grid schedule is the last in the series, then return the first grid schedule in the series
        let found_current = false;
        for (let grid_schedule_id of next_sub.grid_schedule_series) {
          if (found_current) {
            next_grid_schedule_id = grid_schedule_id;
            break;
          }
          if (grid_schedule_id == current_grid_schedule_id) {
            found_current = true;
          }
        }
        if (!next_grid_schedule_id) {
          // If the current grid schedule the last in the series, then return null, as there is no next grid schedule
          if (found_current) {
            return null;
          }
          next_grid_schedule_id = next_sub.grid_schedule_series[0];
        }
      }

      if (!next_grid_schedule_id) {
        return null;
      }

      for (let grid_sched of this.grid_schedules) {
        if (grid_sched.id == next_grid_schedule_id) {
          return grid_sched;
        }
      }

      return null;
    },
    next_subscripion_type_on_all_done() {
      if (
        !this.clinic_invite ||
        this.clinic_invite.billing_strategy == "clinic" ||
        this.clinic_invite.billing_strategy == "free" ||
        (!this.clinic_invite.billing_strategy && this.current_clinic.billing_strategy == "clinic") ||
        (!this.clinic_invite.billing_strategy && this.current_clinic.billing_strategy == "free") ||
        this.current_clinic.has_tag("free")
      ) {
        return null;
      }

      // Check if the clinic is trialing
      if (this.current_clinic.trial_end) {
        let current_date = new Date();
        let trial_end = new Date(this.current_clinic.trial_end);

        // 1 day grace period
        trial_end.setDate(trial_end.getDate() + 1);

        if (current_date < trial_end) {
          return null;
        }
      }

      let user_sub = this.current_user_subscription;

      if (this.current_user_subscription_type) {
        if (this.current_user_subscription_type.suggest_next_sub) {
          return subscription_types[this.app_config.id][this.current_user_subscription_type.suggest_next_sub];
        }
      }
      return null;
    },

    user_is_finished_grid_schedule() {
      if (!this.user_regimen || !this.user_regimen.grid_current_stage || !this.user_regimen.grid_schedule) {
        return false;
      }

      let current_stage_id = this.user_regimen.grid_current_stage;
      let current_grid_stage = null;
      let next_grid_stage = null;
      for (let i in this.user_regimen.grid_schedule.grid) {
        let grid_stage = this.user_regimen.grid_schedule.grid[i];
        if (grid_stage.id == current_stage_id) {
          current_grid_stage = grid_stage;
          next_grid_stage = this.user_regimen.grid_schedule.grid[parseInt(i) + 1];
          break;
        }
      }

      if (!current_grid_stage) {
        return false;
      }
      if (current_grid_stage && !next_grid_stage) {
        return true;
      }

      // Check the next_grid_stage to see if it has any video or interactive exercises that are NOT part of the current_grid_stage
      for (let exercise of next_grid_stage.exercises) {
        if (
          exercise.exercise_type == "interactive" ||
          exercise.exercise_type == "video/clinic" ||
          exercise.exercise_type == "video/builtin" ||
          exercise.exercise_type == "assessment" ||
          exercise.exercise_type == "question" ||
          exercise.exercise_type == "questionnaire"
        ) {
          // Check if the exercise is in the current_grid_stage
          let found = false;
          for (let current_exercise of current_grid_stage.exercises) {
            if (current_exercise.uuid == exercise.uuid) {
              found = true;
              break;
            }
          }
          if (!found) {
            return false;
          }
        }
      }

      return true;
    },

    current_user_subscription_type() {
      if (this.current_user_subscription) {
        return subscription_types[this.app_config.id][this.current_user_subscription.subscription_type];
      }
      return null;
    },

    current_user_subscription() {
      // Check for a valid regular stripe subscription
      for (let sub of this.user_subscriptions) {
        if (sub.invite_id != this.clinic_invite.id) {
          continue;
        }

        if (
          sub.stripe_subscription_id &&
          sub.stripe_subscription_status != "canceled" &&
          sub.stripe_subscription_status != "incomplete_expired" &&
          sub.stripe_subscription_status != "incomplete"
        ) {
          return sub;
        }

        // Check for subscriptions that are not defined through stripe, but rather defined internally
        // using an end-date or a trial-end-date
        if (!sub.stripe_subscription_id) {
          let current_date = new Date();
          // Check the trial_end_date, which is in ISO format
          if (sub.trial_end_date) {
            let trial_end = new Date(sub.trial_end_date);

            // 1 day grace period
            trial_end.setDate(trial_end.getDate() + 1);

            if (current_date < trial_end) {
              return sub;
            }
          }

          // Check the end_date, which is in ISO format
          if (sub.end_date) {
            let end_date = new Date(sub.end_date);

            // 1 day grace period
            end_date.setDate(end_date.getDate() + 1);

            if (current_date < end_date) {
              return sub;
            }
          }
        }
      }

      return null;
    },
    needs_user_subscription() {
      // Check for clinic level config that gives access
      if (
        !this.clinic_invite ||
        this.clinic_invite.billing_strategy == "clinic" ||
        this.clinic_invite.billing_strategy == "free" ||
        (!this.clinic_invite.billing_strategy && this.current_clinic.billing_strategy == "clinic") ||
        (!this.clinic_invite.billing_strategy && this.current_clinic.billing_strategy == "free") ||
        this.current_clinic.has_tag("free")
      ) {
        return false;
      }

      // Check if the clinic is trialing
      if (this.current_clinic.trial_end) {
        let current_date = new Date();
        let trial_end = new Date(this.current_clinic.trial_end);

        // 1 day grace period
        trial_end.setDate(trial_end.getDate() + 1);

        if (current_date < trial_end) {
          return false;
        }
      }

      // Check if the user has an active subscription
      if (this.current_user_subscription) {
        return false;
      }

      // Check to see if they've been assigned a trial grid-schedule
      if (this.user_regimen && this.user_regimen.grid_schedule_id && this.user_regimen.grid_schedule && this.user_regimen.grid_schedule.grid) {
        if (this.free_trial_grid_schedules.includes(this.user_regimen.grid_schedule_id)) {
          // Check the number of days in the free trial grid_schedule
          let num_days_free_trial = 0;
          for (let grid_section of this.user_regimen.grid_schedule.grid) {
            num_days_free_trial += grid_section.interval || 0;
          }

          // Check accept_date on the invite vs todays date
          let accept_date = new Date(this.clinic_invite.accept_date);
          let now = new Date();
          let diff = now - accept_date;
          let num_days = diff / (1000 * 60 * 60 * 24);
          if (num_days < num_days_free_trial) {
            return false;
          }
        }
      }

      // We need a user subscription
      return true;
    },
    user_subscription_future_start_date() {
      if (!this.clinic_invite || this.clinic_invite.billing_strategy != "user") {
        return "";
      }

      for (let sub of this.user_subscriptions) {
        if (
          sub.clinic_id == this.clinic_invite.clinic_id &&
          sub.user_id == this.clinic_invite.user_id &&
          sub.stripe_subscription_status != "canceled" &&
          sub.stripe_subscription_status == "trialing" &&
          sub.start_date
        ) {
          let start_date = new Date(sub.start_date);
          let now = new Date();

          // If it's in the past, return none
          if (start_date < now) {
            return "";
          }

          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          return start_date.toLocaleDateString(this.$i18n.lang(), options);
        }
      }

      return "";
    },
    is_educational() {
      return this.current_clinic && this.current_clinic.has_tag("educational");
    },
    is_nopatients() {
      return this.current_clinic && this.current_clinic.has_tag("nopatients");
    },
    is_free() {
      return this.current_clinic && this.current_clinic.has_tag("free");
    },
    is_hipaa() {
      return this.current_clinic && this.current_clinic.has_tag("hipaa");
    },
    permission_view_staff() {
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_edit_user() {
      return this.current_user.is_admin || this.clinic_role == "admin";
    },
    permission_invite_user() {
      if (this.is_nopatients) {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_reading_texts() {
      if (this.is_educational && this.clinic_role == "staff") {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_video_exercises() {
      if (this.is_educational && this.clinic_role == "staff") {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_printables() {
      if (this.is_educational && this.clinic_role == "staff") {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_invite_staff() {
      return this.clinic_role == "admin";
    },
    permission_edit_clinic() {
      return this.clinic_role == "admin";
    },
    permission_view_assessments() {
      return this.current_user.is_admin || this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_view_billing() {
      // TODO: When we're ready to introduce team-based billing, we'll need to change this
      return false;
      //return this.current_user.is_admin || this.clinic_role == "admin";
    },
    permission_view_grids() {
      return this.current_clinic && (this.clinic_role == "admin" || this.clinic_role == "staff");
    },
    telephone_link() {
      return "tel:" + this.current_clinic.phone;
    },
    address_link() {
      return "https://google.com/maps/place/" + this.physical_address;
    },
    physical_address() {
      let parts = [];

      if (this.current_clinic.physical_address_1) {
        parts.push(this.current_clinic.physical_address_1);
      }
      if (this.current_clinic.physical_address_2) {
        parts.push(this.current_clinic.physical_address_2);
      }
      if (this.current_clinic.physical_sub_locality) {
        parts.push(this.current_clinic.physical_sub_locality);
      }
      if (this.current_clinic.physical_locality) {
        parts.push(this.current_clinic.physical_locality);
      }
      if (this.current_clinic.physical_sub_district) {
        parts.push(this.current_clinic.physical_sub_district);
      }
      if (this.current_clinic.physical_district) {
        parts.push(this.current_clinic.physical_district);
      }
      return parts.join(", ");
    },
    website_view() {
      return this.current_clinic.website.replace(/(^\w+:|^)\/\//, "");
    },
    website_link() {
      if (this.current_clinic.website.startsWith("http://") || this.current_clinic.website.startsWith("https://")) {
        return this.current_clinic.website;
      } else {
        return "http://" + this.current_clinic.website;
      }
    },
    default_invite() {
      if (this.current_user) {
        return {
          user_id: null,
          owner_id: this.current_user.id,
          clinic_id: this.current_clinic.id,
          name: "",
          user_role: "user",
          email: "",
          email_message: "",
          billing_strategy: null,
        };
      }
      return null;
    },
    staff_invite() {
      if (this.current_user) {
        return {
          user_id: null,
          owner_id: this.current_user.id,
          clinic_id: this.current_clinic.id,
          name: "",
          user_role: "staff",
          email: "",
          email_message: "",
        };
      }
      return null;
    },
    user_regimen() {
      for (let user_regimen of this.user_regimens) {
        if (user_regimen.clinic_id == this.current_clinic.id) {
          return user_regimen;
        }
      }
      return null;
    },
    show_history() {
      if (this.user_metadata) {
        for (let metadata of this.user_metadata) {
          if (metadata.category == "completed_grid_schedule") {
            return true;
          }
        }
      }
      if (!this.user_regimen || !this.user_regimen.grid_schedule || !this.user_regimen.grid_schedule.grid.length) {
        return false;
      }
      return this.user_regimen.grid_current_stage != this.user_regimen.grid_schedule.grid[0].id;
    },
  },
  watch: {
    current_clinic() {
      if (this.subpage == "billing") {
        this.$nextTick(() => {
          this.$refs.clinic_billing.load_billing();
        });
      }
    },
  },
  mounted() {
    this.load_clinic();
    this.load_exercises();
    this.load_video_exercises();
  },
  methods: {
    filter_user_line(item, search) {
      if (!search.trim()) {
        return true;
      }

      search = search.toLowerCase();

      let full_name = item.name_given + " " + item.name_family;

      if (item.name_given.toLowerCase().includes(search)) {
        return true;
      } else if (item.name_family.toLowerCase().includes(search)) {
        return true;
      } else if (item.email.toLowerCase().includes(search)) {
        return true;
      } else if (full_name.toLowerCase().includes(search)) {
        return true;
      } else {
        return false;
      }
    },
    filter_invite_line(item) {
      if (!this.user_search.trim()) {
        return true;
      }
      let search = this.user_search.toLowerCase();

      let full_name = item.name + " " + item.name_family;

      if (item.name.toLowerCase().includes(search)) {
        return true;
      } else if (item.name_family.toLowerCase().includes(search)) {
        return true;
      } else if (item.email.toLowerCase().includes(search)) {
        return true;
      } else if (full_name.toLowerCase().includes(search)) {
        return true;
      } else {
        return false;
      }
    },
    billing_page() {
      let self = this;
      this.subpage = "billing";
      Vue.nextTick(() => {
        self.$refs.clinic_billing.load_billing();
      });
    },
    assessments_page() {
      this.subpage = "assessments";
      Vue.nextTick(() => {
        this.$refs.clinic_assessments.load();
      });
    },
    grids_page() {
      this.subpage = "grids";
    },
    load_grids() {
      let self = this;

      axios.get("/api/grid/clinic/" + this.clinic_id + "?include_public").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          self.grid_sets = resp.data.grid_sets;
          self.grid_schedules = resp.data.grid_schedules;
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    load_clinic_info(callback) {
      let clinic_id = this.clinic_id;
      this.$store.dispatch("loadCurrentClinic", { clinic_id, callback });
    },
    load_clinic_users(callback) {
      // Load Clinic Info
      let self = this;
      // Load Clinic user info if admin or staff
      if (this.clinic_role == "admin" || this.clinic_role == "staff") {
        // Load users
        axios.get("/api/clinic/" + self.clinic_id + "/users").then(function (response) {
          self.loaded = true;
          if (response.data.status == "success") {
            let users = response.data.data;
            // Organize alphabetically
            users.sort(function (a, b) {
              if (a.name_family.toUpperCase() > b.name_family.toUpperCase()) {
                return 1;
              } else if (a.name_family.toUpperCase() < b.name_family.toUpperCase()) {
                return -1;
              } else {
                return 0;
              }
            });

            self.all_users = users;
            self.my_users = []; // Users assigned to me
            self.clinic_users = []; // All non-staff users
            self.clinic_staff = []; // All staff users
            self.clinic_admin = []; // All admin users

            for (var i in self.all_users) {
              let user = self.all_users[i];
              if (user.user_role == "user") {
                self.clinic_users.push(user);
                self.my_users.push(user);
              }
              if (user.user_role == "staff" || user.user_role == "admin") {
                self.clinic_staff.push(user);
                Vue.set(self.edit_staff_role, user.id, false);
              }
              if (user.user_role == "admin") {
                self.clinic_admin.push(user);
              }
            }
            if (callback) {
              callback();
            }
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
      }
    },
    load_unaccepted_invites(callback) {
      // Load Unaccepted Invites
      let self = this;
      if (this.clinic_role == "admin" || this.clinic_role == "staff") {
        axios.get("/api/clinic/" + self.clinic_id + "/invites?accepted=false").then(function (response) {
          if (response.data.status == "success") {
            self.unaccepted_user_invites = [];
            self.unaccepted_staff_invites = [];
            self.my_user_invites = [];

            for (var i in response.data.data) {
              let invite = response.data.data[i];
              if (!invite.user_id) {
                if (invite.user_role == "user") {
                  self.unaccepted_user_invites.push(invite);
                  self.my_user_invites.push(invite);
                }
                if (invite.user_role == "staff" || invite.user_role == "admin") {
                  self.unaccepted_staff_invites.push(invite);
                }
              }
            }
            if (callback) {
              callback();
            }
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
      }
    },
    load_user_activity: function (callback) {
      // Load Clinic user activity info if admin or staff
      let self = this;

      let d = new Date();
      let offset = d.getTimezoneOffset();
      axios.get("/api/clinic/" + self.clinic_id + "/activity_summary?offset=" + offset).then(function (response) {
        if (response.data.status == "success") {
          self.activity_summary = response.data.data;
          if (callback) {
            callback();
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    load_user_latest_activity: function (callback) {
      // Load Clinic user latest-activity info if admin or staff
      let self = this;
      axios.get("/api/clinic/" + self.clinic_id + "/latest_activity").then(function (response) {
        if (response.data.status == "success") {
          self.latest_activity = response.data.data;
          self.latest_activity_loaded = true;
          if (callback) {
            callback();
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    load_clinic: function () {
      let self = this;
      this.load_clinic_info(function () {
        self.load_grids();
        self.load_clinic_users(function () {
          self.load_unaccepted_invites(function () {
            self.assign_ownership();
            self.load_user_activity();
            self.load_user_latest_activity();
          });
        });
      });
    },
    delete_invite(invite_id, type = "user") {
      let force = false;
      var invite_title;
      if (type == "user") {
        invite_title = this.$t("common.invite");
      }
      if (type == "staff") {
        invite_title = this.$t("common.staff");
        force = true;
      }

      if (!confirm(this.$t("common.delete") + " " + invite_title + "?")) {
        return;
      }

      let self = this;
      axios.delete("/api/invite/" + invite_id + "?force=" + force).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", invite_title + " " + self.$t("common.deleted"));
          if (type == "user") {
            self.load_unaccepted_invites(function () {
              self.assign_ownership();
            });
          }
          if (type == "staff") {
            self.load_clinic_users(function () {
              self.assign_ownership();
            });
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    archive_user(user) {
      if (!confirm(this.$t("common.archive") + " " + user.name_given + " " + user.name_family[0] + "?")) {
        return;
      }

      // Load the invite, modify archive status, then repost it
      let self = this;
      axios.get("/api/invite/" + user.invite_id).then(function (response) {
        if (response.data.status == "success") {
          let invite = response.data.data;
          invite.archived = true;
          axios.put("/api/invite/" + user.invite_id, invite).then(function (response) {
            if (response.data.status == "success") {
              messageBus.$emit("success", self.$t("clinic_page.user_archived"));
              self.load_clinic_users(function () {
                self.assign_ownership();
              });
            } else {
              messageBus.$emit("error", response.data.error_message);
            }
          });
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    unarchive_user(user) {
      // Load the invite, modify archive status, then repost it
      let self = this;
      axios.get("/api/invite/" + user.invite_id).then(function (response) {
        if (response.data.status == "success") {
          let invite = response.data.data;
          invite.archived = false;
          invite.expiry_date = null;
          axios.put("/api/invite/" + user.invite_id, invite).then(function (response) {
            if (response.data.status == "success") {
              messageBus.$emit("success", self.$t("clinic_page.user_restored"));
              self.load_clinic_users(function () {
                self.assign_ownership();
                self.load_archived_users();
              });
            } else {
              messageBus.$emit("error", response.data.error_message);
            }
          });
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    view_archived_users() {
      this.archived_users_dialog = true;
      this.load_archived_users();
    },
    view_reading_texts() {
      this.reading_texts_dialog = true;
    },
    view_video_exercises() {
      this.video_exercises_dialog = true;
    },
    view_printables() {
      this.printables_dialog = true;
    },
    load_archived_users() {
      let self = this;
      axios.get("/api/clinic/" + self.current_clinic.id + "/users?archived=true").then(function (response) {
        if (response.data.status == "success") {
          self.archived_users = response.data.data;
          self.archive_report_dialogs = {};
          for (var i in self.archived_users) {
            let user = self.archived_users[i];
            if (user.user_role == "user") {
              Vue.set(self.archive_report_dialogs, user.id, false);
            }
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    masquerade_as_user(user) {
      let masquerade = {
        clinic_id: this.clinic_id,
        user_id: user.id,
      };

      let self = this;
      axios.post("/api/user/masquerade", masquerade).then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          logout_helpscout();
          window.location.reload();
        } else {
          if (resp.error_code == "CannotMasquerade") {
            messageBus.$emit("error", self.$t("clinic_page.cannot_masquerade_as_this_user"));
          } else {
            messageBus.$emit("api-error", resp);
          }
        }
      });
    },
    archive_report_dialog_open(user_id) {
      Vue.set(this.archive_report_dialogs, user_id, true);
    },
    load_exercises: function () {
      let self = this;
      axios.get("/api/exercise").then(function (response) {
        if (response.data.status == "success") {
          self.exercises = response.data.data;
        }
      });
    },
    load_video_exercises: function () {
      let self = this;
      axios.get("/api/video_exercise").then(function (response) {
        if (response.data.status == "success") {
          self.video_exercises = response.data.data;
        }
      });
    },
    archive(_user_id, _index) {
      // TODO: Implement archive
    },
    assign_ownership() {
      if (this.clinic_staff.length == 0) {
        return;
      }

      // uuid for user, invites, and regimens => staff
      this.ownership = {};

      // Create an index of staff
      var staff_index = {};
      for (var i in this.clinic_staff) {
        staff_index[this.clinic_staff[i].id] = this.clinic_staff[i];
      }

      // eslint-disable-next-line
      for (var i in this.clinic_users) {
        // It's owned by the invite owner.
        if (staff_index[this.clinic_users[i].invite_owner]) {
          let staff = staff_index[this.clinic_users[i].invite_owner];
          if (staff) {
            this.ownership[this.clinic_users[i].id] = {
              id: staff.id,
              assigned: true,
              name: staff.name_given + " " + staff.name_family,
            };
          }
        }
        if (!this.ownership[this.clinic_users[i].id]) {
          this.ownership[this.clinic_users[i].id] = {
            id: null,
            assigned: false,
            name: "Unassigned",
          };
        }
      }

      // eslint-disable-next-line
      for (var i in this.unaccepted_user_invites) {
        if (staff_index[this.unaccepted_user_invites[i].owner_id]) {
          let staff = staff_index[this.unaccepted_user_invites[i].owner_id];

          this.ownership[this.unaccepted_user_invites[i].id] = {
            id: staff.id,
            assigned: true,
            name: staff.name_given + " " + staff.name_family,
          };
        } else {
          this.ownership[this.unaccepted_user_invites[i].id] = {
            id: null,
            assigned: false,
            name: "Unassigned",
          };
        }
      }
    },

    open_edit_staff_role(id) {
      Vue.set(this.edit_staff_role, id, true);
    },
    close_edit_staff_role(id) {
      Vue.set(this.edit_staff_role, id, false);
    },
  },
};
</script>

<style>
.regimen_print_icon:hover {
  color: black;
}

.clinic_page_edit_info_button {
  float: right;
}
</style>
