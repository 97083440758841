<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-text-field v-model="localValue.title" outlined :label="$t('common.title')" type="text" />

        <v-textarea v-model="local_text" outlined :label="$t('regimen_email_edit.plain_text')" rows="5" />

        <v-textarea v-model="local_html" outlined label="HTML" rows="5" style="font-size: smaller; font-family: 'Courier New', Courier, monospace" />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let local_html = this.decodeIfBase64(this.value.config.html);
    let local_text = this.decodeIfBase64(this.value.config.text);

    let data = {
      local_html,
      local_text,
    };
    return data;
  },
  computed: {
    localValue: {
      get() {
        let value = this.value;
        return value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  watch: {
    local_html() {
      let encoded_html = this.encodeToBase64(this.local_html);
      Vue.set(this.localValue.config, "html", encoded_html);
    },
    local_text() {
      let encoded_text = this.encodeToBase64(this.local_text);
      Vue.set(this.localValue.config, "text", encoded_text);
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
    decodeIfBase64(str) {
      if (this.isBase64(str)) {
        const binary = atob(str); // Decode Base64 to binary string
        const bytes = Uint8Array.from(binary, (char) => char.charCodeAt(0)); // Convert binary string to byte array
        return new TextDecoder().decode(bytes); // Convert bytes to UTF-8 string
      }
      return str;
    },
    isBase64(str) {
      if (typeof str !== "string" || str.trim() === "") return false;

      try {
        const decoded = atob(str);
        // Ensure the decoded string can be re-encoded properly
        return btoa(decoded) === str;
      } catch (err) {
        return false;
      }
    },
    encodeToBase64(str) {
      const encoder = new TextEncoder();
      const bytes = encoder.encode(str); // Convert string to UTF-8 bytes
      const binary = Array.from(bytes, (byte) => String.fromCharCode(byte)).join(""); // Convert bytes to binary string
      return btoa(binary);
    },
  },
};
</script>

<style></style>
