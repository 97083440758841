<template>
  <div class="activity_summary">
    <v-tooltip v-for="(day, index) in days" :key="index" top>
      <template v-slot:activator="{ on }">
        <div class="day_wrapper text-center">
          <v-badge color="green darken-2" overlap :value="day.report_feedback">
            <template v-slot:badge>
              <v-icon x-small color="white">mdi-comment</v-icon>
            </template>
            <v-btn
              class="day"
              :color="day.color"
              block
              tile
              :width="dense ? '32px' : '36px'"
              :height="dense ? '32px' : '36px'"
              depressed
              v-on="on"
              @click="calendar_dialog = true"
              >{{ day.daychar }}</v-btn
            >
          </v-badge>
        </div>
      </template>
      <div>
        <div style="font-weight: bold">{{ day.datelabel }}</div>
        <div v-for="(day_summary, day_index) in day.activity_summary" :key="day_index">
          {{ day_summary }}
        </div>
      </div>
    </v-tooltip>

    <!-- Detailed calendary view for user -->
    <v-dialog v-model="calendar_dialog" max-width="1200">
      <ActivityCalendar
        v-if="calendar_dialog"
        :user="user"
        :clinic_id="clinic_id"
        :exercises="exercises"
        :video_exercises="video_exercises"
        @close="calendar_dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import ActivityCalendar from "./ActivityCalendar.vue";
export default {
  components: { ActivityCalendar },
  props: {
    summary: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    clinic_id: {
      type: String,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
    },
    video_exercises: {
      type: Object,
      required: true,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calendar_dialog: false,
    };
  },
  computed: {
    days() {
      var date = new Date();

      var days = [];

      var i;
      for (i = 0; i < 7; i++) {
        let day;
        let datestring = this.datestring(date);
        if (this.summary.days[datestring]) {
          day = this.summary.days[datestring];
        } else {
          day = {
            date: datestring,
            activities: {},
          };
        }

        // Set the button label
        if (this.$i18n.lang() == "he") {
          const he_days = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
          day.daychar = he_days[date.getDay()];
        } else {
          let weekday = date.toLocaleString(this.$i18n.lang(), {
            weekday: "short",
          });
          day.daychar = weekday.charAt(0).toUpperCase();
        }

        // Set the visible date
        day.datelabel = this.datelabel(date);

        // Determine the colour:
        if (day.activities["daily_regimen_complete"]) {
          day.color = "green darken-1";
        } else if (day.activities["create_report"]) {
          day.color = "green lighten-1";
        } else if (day.activities["get_clinic"]) {
          day.color = "blue lighten-2";
        } else {
          if (this.$vuetify.theme.dark) {
            day.color = "grey darken-2";
          } else {
            day.color = "grey lighten-2";
          }
        }

        // Set the tooltip activity text and the color
        var activity_summary = [];
        if (Object.keys(day.activities).length == 0) {
          activity_summary.push(this.$t("common.no_activity"));
        } else {
          // Did they complete a daily regimen?
          if (day.activities["daily_regimen_complete"] > 0) {
            activity_summary.push(this.$t("common.daily_regimen_completed"));
          }

          // Did they complete an activity?
          if (day.activities["create_report"] > 0) {
            var exercises_completed = day.activities["create_report"];
            activity_summary.push(this.$t("common.exercises_completed", { num: exercises_completed }));
          }

          // Did they at least log in?
          else if (day.activities["get_clinic"] > 0) {
            activity_summary.push(this.$t("activity_summary.logged_no_exercises_completed"));
          }

          // TODO: Did they compelte a non-regimen activity?
        }

        // Set the message icon
        if (day.activities["report_feedback"]) {
          day.report_feedback = true;
          activity_summary.push(this.$t("activity_summary.feedback_left_for_exercise"));
        } else {
          day.report_feedback = false;
        }

        day.activity_summary = activity_summary;

        // Add the day to the week
        days.push(day);

        // Go to previous day
        var ms = date.getTime() - 86400000;
        date = new Date(ms);
      }

      if (this.$vuetify.rtl) {
        return days;
      } else {
        return days.reverse();
      }
    },
  },
  methods: {
    datestring(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0");
      var yyyy = date.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    datelabel(date) {
      var options = {
        month: "long",
        day: "numeric",
        weekday: "long",
      };
      let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
      return formatter.format(date);
    },
  },
};
</script>

<style>
.activity_summary {
  clear: both;
  width: 320px;
}

.activity_summary .day_wrapper {
  float: left;
  margin-right: 6px;
}
</style>
