import Vue from "vue";
import VueI18n from "vue-i18n";
import cookie from "js-cookie";

interface LanguageDetails {
  name: string;
  nativeName: string;
  alphabet?: string;
  single_case?: boolean;
  rtl?: boolean;
  numerals?: string;
  status?: { [app_id: string]: string };
}

interface LangCodes {
  [languageCode: string]: LanguageDetails;
}

const lang_codes = require("../../data/lang_codes.json") as LangCodes;

const app_id = window.OpticalGymApp;

Vue.use(VueI18n);

export enum Locales {
  EN = "en",
  FR = "fr",
  ES = "es",
  HE = "he",
  AR = "ar",
  PT = "pt",
  EN_VISIONX = "en-visionx",
  EN_VEST = "en-vest",
}

export const LOCALES = [
  { value: Locales.EN, caption: "English" },
  { value: Locales.FR, caption: "Français" },
  { value: Locales.ES, caption: "Español" },
  { value: Locales.HE, caption: "עברית" },
  { value: Locales.AR, caption: "العربية" },
  { value: Locales.PT, caption: "Português (br)" },
  { value: Locales.EN_VISIONX, caption: "English (VisionX)" },
  { value: Locales.EN_VEST, caption: "English (vestibular.vision)" },
];

const en = require("./locales/en.yml");
const fr = require("./locales/fr.yml");
const es = require("./locales/es.yml");
const he = require("./locales/he.yml");
const pt = require("./locales/pt.yml");
const ar = require("./locales/ar.yml");
const en_visionx = require("./locales/en_visionx.yml");
const en_vest = require("./locales/en_vest.yml");

export const messages = {
  [Locales.EN]: en,
  [Locales.FR]: fr,
  [Locales.ES]: es,
  [Locales.HE]: he,
  [Locales.PT]: pt,
  [Locales.AR]: ar,
  [Locales.EN_VISIONX]: en_visionx,
  [Locales.EN_VEST]: en_vest,
};

// Determine default language - first check the URL "lang" query param, then for a cookie, then the browser's language
let urlParams = new URLSearchParams(window.location.search);
let selected_locale = urlParams.get("lang");
if (!selected_locale) {
  selected_locale = cookie.get("opticalgym_locale") || null;
}
if (!selected_locale) {
  selected_locale = "en";
  let full_locale = window.navigator.language;
  if (full_locale) {
    let short_locale = full_locale.substring(0, 2).toLowerCase();

    if (lang_codes[short_locale]?.status?.[app_id] === "production") {
      selected_locale = short_locale;
    }
  }
}

let lang = selected_locale.slice(0, 2).toLocaleLowerCase();
let locale = lang;

if (window.OpticalGymApp == "visionx") {
  locale += "-visionx";
}
if (window.OpticalGymApp == "vest") {
  locale += "-vest";
}

// Extend the VueI18n class to add a lang() method, which is the language stripped of full locale info (e.g. 'en' from 'en-US', or 'fr' from 'fr-visionx')
export class OpticalgymVueI18n extends VueI18n {
  lang(): string {
    if (this.locale) {
      return this.locale.slice(0, 2) || "en";
    }
    return "en";
  }
}

let i18n = new OpticalgymVueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages: messages,
  silentTranslationWarn: true,
});

// Set the HTML 'lang' attribute
document.documentElement.lang = i18n.lang();

export default i18n;
