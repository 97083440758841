<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.activity_calendar") }} - {{ user.name_given }} {{ user.name_family }} - {{ date }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text style="min-height: 800px; overflow: auto; padding-top: 50px">
      <div v-for="(report, index) in reports" :key="index">
        <RegimenReportCard
          v-if="get_exercise(report)"
          style="float: left; margin-right: 50px"
          :width="small_screen ? 440 : 500"
          :display_graph="false"
          :reports="[report]"
          :exercise="get_exercise(report)"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import RegimenReportCard from "./RegimenReportCard";
import axios from "axios";
import { mapState } from 'vuex';

export default {
  components: { RegimenReportCard },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
    },
    video_exercises: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reports: [],
    };
  },
  computed: {
    ...mapState(["small_screen"]),
  },
  mounted() {},
  methods: {
    get_exercise(report) {
      if (report.exercise_type == "interactive" || !report.exercise_type) {
        return this.exercises[report.exercise_id];
      } else if (report.exercise_type == "custom") {
        return {
          title: this.$t('common.custom_one_off'),
          exercise_type: "custom",
        }
      }
      else if (report.exercise_type == "questionnaire" || report.exercise_type == "question") {
        return Object.assign({exercise_type: report.exercise_type}, report.params);
      }
      else {
        return this.video_exercises[report.exercise_id];
      }
    },
    load() {
      this.reports = [];
      let invite_id = this.user.invite_id;
      let self = this;

      let d = new Date();
      let offset = d.getTimezoneOffset();

      let start = new Date(this.date);
      start.setTime(start.getTime() + offset * 60 * 1000);
      let start_iso8601 = start.toISOString().slice(0, -2);

      axios
        .get(
          "/api/invite/" +
            invite_id +
            "/regimen/reports?start=" +
            start_iso8601
        )
        .then(function (response) {
          self.loading = false;
          if (response.data.status == "success") {
            self.reports = response.data.data;
          }
        });
    },
    close() {
      this.reports = [];
      this.$emit("close");
    },
  },
};
</script> 