<template>
  <div>
    <!-- Payment Method Status -->
    <v-layout align-center justify-center>
      <v-sheet elevation="3" style="width: 1000px; padding: 50px; margin: 30px">
        <v-layout justify-space-between>
          <v-flex xs4 style="font-size: 16pt; font-weight: 450">{{ $t("clinic_billing.payment_and_billing") }}</v-flex>
          <v-flex xs6 offset-xs2>
            <v-select
              v-if="current_user.is_admin"
              v-model="localValue.payment_preference"
              :items="payment_preference_items"
              :label="$t('clinic_billing.select_payment_preference')"
              outlined
              append-icon="mdi-shield"
              @change="maybe_mount_stripe"
            ></v-select>
          </v-flex>
        </v-layout>

        <!-- Billing Strategy -->
        <div v-if="allow_change_billing_strategy" class="mt-6 ml-12">
          <h4>{{ $t("clinic_billing.select_billing_method") }}</h4>
          <p style="font-size: smaller">{{ $t("clinic_billing.select_billing_method_desc") }}</p>
          <v-radio-group v-model="localValue.billing_strategy" mandatory>
            <v-radio :label="$t('billing_payment.clinic_pays')" value="clinic"></v-radio>
            <div class="ml-8 mt-n2 mb-3" style="font-size: smaller">
              {{ $t("clinic_billing.clinic_pays_desc") }} ({{ $t("clinic_billing.user_seat_per", {price: clinic_subscription_price_amount}) }})
            </div>
            <v-radio :label="$t('billing_payment.user_pays')" value="user"></v-radio>
            <div class="ml-8 mt-n2 mb-3" style="font-size: smaller">
              {{ $t("clinic_billing.user_pays_desc") }} ({{ user_subscription_price_amount }} / {{ $t("common.month") }})
            </div>
          </v-radio-group>
        </div>

        <!-- Payment Details -->
        <v-layout align-center justify-center>
          <v-sheet
            elevation="2"
            style="padding: 30px; text-align: center; width: 800px"
            :class="!current_user.is_admin && !allow_change_billing_strategy ? 'mt-6' : ''"
          >
            <div v-if="localValue.billing_strategy == 'user'" style="margin-bottom: 20px; font-weight: 450">
              <strong>{{ $t("clinic_billing.user_pays") }}</strong>

              <p style="font-size: smaller" class="mt-4">{{ user_subscription_price_amount }} / {{ $t("common.month") }}</p>

              <v-divider class="mt-6 mb-6"></v-divider>
              <div class="mb-12">
                {{ $t("clinic_billing.user_pays_payment_desc") }}
                <span v-if="localValue.payment_preference == 'charge_automatically' && localValue.card_on_file">{{
                  $t("clinic_billing.user_pays_payment_desc_has_payment")
                }}</span>
                <span v-else-if="localValue.payment_preference == 'charge_automatically' && !localValue.card_on_file">
                  {{ $t("clinic_billing.user_pays_payment_desc_no_payment") }}
                </span>
                <span v-else-if="localValue.payment_preference == 'send_invoice'"> {{ $t("clinic_billing.manual_payments_text") }}. </span>
              </div>
            </div>
            <div v-else-if="localValue.payment_preference == 'charge_automatically'">
              <strong>
                {{ $t("clinic_billing.automatic_credit_card_payment") }}
              </strong>

              <div style="font-size: smaller" class="mt-4">
                {{ $t("clinic_billing.we_automatically_charge_your_credit_card") }}
              </div>
            </div>
            <div v-else-if="localValue.payment_preference == 'send_invoice'">
              <strong>
                {{ $t("clinic_billing.manual_payments") }}
              </strong>

              <p style="font-size: smaller" class="mt-4">
                {{ $t("clinic_billing.manual_payments_text") }}
              </p>
            </div>

            <!-- Credit Card Data Entry -->
            <div v-if="localValue.payment_preference == 'charge_automatically'" style="margin-top: 20px">
              <!-- Card on file -->
              <v-layout v-if="localValue.card_on_file" justify-space-between class="mb-6">
                <v-flex style="text-align: left">
                  <v-icon left>fab fa-cc-{{ localValue.card_on_file_brand }}</v-icon>
                  <span style="font-weight: 450">{{ $t("clinic_billing.payment_method") }}:</span>
                  {{ $t("clinic_billing.ending_in", {payment_brand: payment_brand, last_4_digits:  localValue.card_on_file }) }}
                </v-flex>
                <v-flex offset-xs2>
                  <v-btn @click="update_card" small> {{ $t("clinic_billing.update_card") }}</v-btn>
                </v-flex>
              </v-layout>

              <!-- New Card -->
              <v-layout v-else justify-space-between>
                <v-flex md8>
                  <div id="stripe-card-element" :style="stripe_card_element_style"></div>
                </v-flex>
                <v-flex v-if="!localValue.card_on_file" md3 style="margin-top: 10px">
                  <v-text-field v-model="localValue.name_on_card" outlined :label="$t('clinic_billing.name_on_card')" dense></v-text-field>
                </v-flex>
              </v-layout>
            </div>

            <!-- Billing Address -->
            <div v-if="show_billing_entry">
              <div>
                <v-select
                  v-if="billing_contact_items && billing_contact_items.length > 1"
                  v-model="localValue.billing_contact"
                  :items="billing_contact_items"
                  :label="$t('clinic_billing.contact_person')"
                ></v-select>

                <v-text-field v-model="localValue.billing_address_1" :label="$t('common.address')" type="text" dense />
                <v-text-field v-model="localValue.billing_address_2" type="text" dense />
                <v-layout>
                  <v-flex md4>
                    <v-text-field v-model="localValue.billing_locality" :label="$t('common.city')" type="text" />
                  </v-flex>
                  <v-flex v-if="current_locale_country.district_name" md3 offset-xs1>
                    <v-select v-model="localValue.billing_district" :items="billing_district_items" :label="billing_district_name" />
                  </v-flex>
                  <v-flex md3 offset-xs1>
                    <v-text-field v-model="localValue.billing_postal" :label="current_locale_country.postal_name" type="text" />
                  </v-flex>
                </v-layout>
                <v-layout justify-space-between>
                  <v-flex md4>
                    <v-select
                      v-model="localValue.billing_country"
                      :disabled="localValue.subscription_status != null"
                      :items="all_countries"
                      :label="$t('common.country')"
                    />
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-sheet>
        </v-layout>

        <v-layout justify-space-between class="mt-6 ml-6">
          <v-flex md4>
            <v-text-field
              v-if="show_billing_entry"
              v-model="localValue.promo_code"
              type="text"
              :label="$t('clinic_billing.coupon_code')"
              dense
              outlined
            />
          </v-flex>

          <v-flex md-2 class="text-right mr-4">
            <v-btn color="primary" :disabled="in_flight" :loading="in_flight" @click="save_billing">
              {{ $t("clinic_billing.update_payment_info")}}
            </v-btn>
          </v-flex>
        </v-layout>

        <div v-if="error_message" class="mt-6 ml-4 mr-4">
          <v-alert type="error">{{ error_message }}</v-alert>
        </div>
        <div v-else-if="needs_save" style="font-size: smaller" class="mt-6 ml-4 mr-4">
          <v-alert type="info" outlined dense> {{ $t("billing_payment.unsaved_changes") }}</v-alert>
        </div>
      </v-sheet>
    </v-layout>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js/pure";
import all_countries from "../../../data/all_countries_list.json";
import { mapState } from "vuex";
import Clinic from "../classes/clinic";
import locales from "../../../data/locales.yml";

export default {
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
    stripe_public_key: {
      type: String,
      required: true,
    },
    context: {
      type: String, // "clinic" or "user"
      required: true,
    },
    context_object: {
      type: Object, // Can either be a clinic object or an invite
      required: true,
    },
    allow_change_billing_strategy: {
      type: Boolean,
      default: false,
    },
    clinic_admins: {
      type: Array,
      required: true,
    },
    user_subscription_price_amount: {
      type: String,
      default: "",
    },
    clinic_subscription_price_amount: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      stripe_loaded: false,
      stripe_mounted: false,
      stripe: null,
      stripe_elements: null,
      stripe_card: null,
      all_countries: all_countries,
      error_message: "",
      in_flight: false,
      show_billing_entry: false,
      needs_save: false,
      localValue: JSON.parse(JSON.stringify(this.value)), // Deep copy of the value prop to avoid modifying the parent's state
      billing_strategy_items: [
        { text: this.$t("billing_payment.clinic_pays"), value: "clinic" },
        { text: this.$t("billing_payment.user_pays"), value: "user" },
      ],
      allow_clinic_billing_items: [
        {
          text: this.$t("common.yes"),
          subtitle: this.$t("billing_payment.allow_clinic_billing_true_subtitle"),
          value: true,
        },
        {
          text: this.$t("common.no"),
          subtitle: this.$t("billing_payment.allow_clinic_billing_false_subtitle"),
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["current_user", "current_clinic_id"]),
    watchableLocalValue() {
      return Object.assign({}, this.localValue);
    },
    current_locale_country() {
      if (locales[this.localValue.billing_country]) {
        return locales[this.localValue.billing_country];
      }

      var country_name = this.localValue.billing_country;
      for (var country of all_countries) {
        if (country.value == this.localValue.billing_country) {
          country_name = country.text;
          break;
        }
      }

      return {
        code: this.localValue.billing_country,
        name: country_name,
        postal_name: "Postal Code",
        currency: "USD",
        price: "$6.99 USD",
        stripe_user_coupon: "",
        stripe_price: {
          test: "price_1GygSoFpF80gxM91mPohhQRE",
          live: "price_1GygWYFpF80gxM91Tdz0Yfv6",
        },
        languages: {
          "en-US": {
            code: "en-US",
            name: "English",
          },
        },
      };
    },
    card_style() {
      // Card style
      let card_style = {
        base: {
          color: "#32325d",
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          colorText: "black",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.6)",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };

      if (this.$vuetify.theme.isDark) {
        card_style.base.colorText = "white";
        card_style.base.color = "white";
        card_style.base["::placeholder"].color = "rgba(255, 255, 255, 0.6)";
        card_style.base.backgroundColor = "rgb(30, 30, 30)";
      }

      return card_style;
    },
    stripe_card_element_style() {
      let style = "margin-top: 10px; width: 520px;";
      if (this.$vuetify.theme.isDark) {
        style += "border: 1px solid rgb(118, 118, 118); background-color: rgb(30, 30, 30);";
      } else {
        style += "border: 1px solid rgba(0, 0, 0, 0.38)";
      }

      return style;
    },
    billing_contact_items() {
      let items = [];
      this.clinic_admins.forEach((admin) => {
        items.push({
          value: admin.id,
          text: admin.name_given + " " + admin.name_family,
        });
      });
      return items;
    },
    payment_preference_items() {
      let items = [
        {
          value: "charge_automatically",
          text: this.$t("clinic_billing.automatic_credit_card_payment"),
        },
        {
          value: "send_invoice",
          text: this.$t("clinic_billing.send_me_an_invoice_every_month"),
        },
      ];
      if (!this.localValue.payment_preference) {
        items.unshift({
          value: "",
          text: this.$t("clinic_billing.please_select_a_payment_method"),
        });
      }
      return items;
    },
    //localValue: {
    //  get() {
    //    return this.local_value;
    //  },
    //  set(localValue) {
    //    // If there's only one billing contact, then set it
    //    if (this.clinic_admins.length == 1) {
    //      localValue.billing_contact = this.clinic_admins[0].id;
    //    }
    //    Vue.set(this, "local_value", localValue);
    //    this.maybe_mount_stripe();
    //  },
    //},
    billing_district_items() {
      let items = [];
      let country = this.current_locale_country;
      if (country) {
        if (country.districts) {
          let districts = country.districts;
          for (var code in districts) {
            items.push({ value: code, text: districts[code].name });
          }
        }
      }

      items.sort((a, b) => (a.value > b.value ? 1 : -1));

      return items;
    },
    billing_district_name() {
      let country = this.current_locale_country;
      if (country) {
        return country.district_name;
      } else {
        return "";
      }
    },
    payment_brand() {
      if (this.localValue.card_on_file_brand == "visa") {
        return "Visa";
      } else if (this.localValue.card_on_file_brand == "visa_debit") {
        return "Visa (debit)";
      } else if (this.localValue.card_on_file_brand == "mastercard") {
        return "Mastercard";
      } else if (this.localValue.card_on_file_brand == "mastercard_debit") {
        return "Mastercard (debit)";
      } else if (this.localValue.card_on_file_brand == "mastercard_prepaid") {
        return "Mastercard (prepaid)";
      } else if (this.localValue.card_on_file_brand == "amex") {
        return "American Express";
      }

      return this.localValue.card_on_file_brand;
    },
  },
  watch: {
    watchableLocalValue: {
      handler(val, oldVal) {
        if (this.clinic_admins.length == 1) {
          this.localValue.billing_contact = this.clinic_admins[0].id;
        }

        if (val.billing_country != oldVal.billing_country) {
          this.localValue.billing_district = null;
          this.localValue.billing_postal = "";
          this.needs_save = true;
        }
        if (oldVal.billing_strategy != val.billing_strategy) {
          if (val.billing_strategy == "clinic") {
            this.show_billing_entry = true;
          }
          if (val.billing_strategy == "user") {
            this.show_billing_entry = false;
          }
          this.needs_save = true;
          this.maybe_mount_stripe();
        }
        if (oldVal.payment_preference != val.payment_preference) {
          this.needs_save = true;
          this.maybe_mount_stripe();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.load_stripe();

    // If there's only one billing contact, then set it
    if (this.clinic_admins.length == 1) {
      this.localValue.billing_contact = this.clinic_admins[0].id;
    }
    if (this.localValue.billing_strategy == "clinic" && this.localValue.payment_preference == "charge_automatically") {
      this.show_billing_entry = true;
    }
  },
  methods: {
    update_card() {
      this.localValue.card_on_file = null;
      this.localValue.payment_method = "";
      this.show_billing_entry = true;
      this.mount_stripe();
      this.needs_save = true;
    },
    mount_stripe() {
      let self = this;

      Vue.nextTick(() => {
        self.stripe_card.mount("#stripe-card-element");
        self.stripe_mounted = true;
      });
    },
    load_stripe() {
      let key = this.stripe_public_key;
      loadStripe(key, { locale: this.$i18n.lang() }).then((stripe) => {
        this.stripe = stripe;
        this.stripe_elements = this.stripe.elements();
        this.stripe_card = this.stripe_elements.create("card", {
          hidePostalCode: true,
          style: this.card_style,
        });

        this.stripe_card.on("change", () => {
          this.show_billing_entry = true;
          this.needs_save = true;
        });
        this.stripe_loaded = true;
        this.maybe_mount_stripe();
      });
    },
    maybe_mount_stripe() {
      Vue.nextTick(() => {
        // If stripe card element does not exist, then mark it as not mounted
        let elem = document.getElementById("stripe-card-element");
        if (!elem || elem.childElementCount == 0) {
          this.stripe_mounted = false;
        }

        if (this.stripe_loaded && !this.stripe_mounted && this.localValue.payment_preference != "send_invoice" && !this.localValue.card_on_file) {
          this.mount_stripe();
        }
      });
    },
    reset() {
      this.stripe_loaded = false;
      this.stripe_mounted = false;
    },
    check_address() {
      if (!this.localValue.billing_address_1) {
        this.error_message = this.$t("clinic_billing.please_provide_a_billing_street_address");
        return false;
      }
      if (!this.localValue.billing_locality) {
        this.error_message = this.$t("clinic_billing.please_provide_a_city_in_billing_address");
        return false;
      }
      if (!this.localValue.billing_district) {
        if (this.current_locale_country.district_name) {
          let district_name = this.current_locale_country.district_name;
          this.error_message = this.$t("clinic_billing.please_select_a", { district_name: district_name});
          return false;
        }
      }
      if (!this.localValue.billing_postal) {
        this.error_message = this.$t("clinic_billing.please_provide_a_billing", { postal_name: this.current_locale_country.postal_name });
        return false;
      }
      return true;
    },
    save_billing() {
      this.error_message = "";

      // Check the address if we need to
      if (this.show_billing_entry && !this.check_address()) return;

      // If the billing strategy is user and we haven't entered any payment info, then just save the billing info without saving payment info
      if (this.localValue.billing_strategy == "user" && !this.show_billing_entry) {
        this.in_flight = true;
        axios
          .post("/api/clinic/" + this.context_object.id + "/billing_strategy", {
            billing_strategy: this.localValue.billing_strategy,
            allow_clinic_billing: this.localValue.allow_clinic_billing,
          })
          .then((response) => {
            let resp = response.data;
            if (resp.status == "success") {
              this.reload_clinic();
              messageBus.$emit("success", this.$t("billing_paymment.billing_preferences_updated"));
              this.$emit("input", this.localValue);
              this.needs_save = false;
            } else {
              messageBus.$emit("api-error", resp);
            }
            this.in_flight = false;
          });
        return;
      }

      if (!this.localValue.name_on_card) {
        this.error_message = this.$t("clinic_billing.please_provide_the_name_listed_on_front_of_credit_card");
        return;
      }

      var self = this;
      self.in_flight = true;
      let email = "";
      this.clinic_admins.forEach((admin) => {
        if (admin.id == this.localValue.billing_contact) {
          email = admin.email;
        }
      });
      if (!email) {
        this.error_message = this.$t("clinic_billing.please_select_billing_contact_person");
        return;
      }

      var billing_details = {
        name: this.localValue.name_on_card,
        email: email,
        address: {
          line1: this.localValue.billing_address_1,
          line2: this.localValue.billing_address_2,
          city: this.localValue.billing_locality,
          state: this.localValue.billing_district,
          postal_code: this.localValue.billing_postal,
          country: this.localValue.billing_country,
        },
      };

      if (!this.localValue.payment_method && this.localValue.payment_preference == "charge_automatically") {
        var url;
        if (this.context == "clinic") {
          url = "/api/clinic/" + this.context_object.id + "/billing/setup_intent";
        }
        axios.post(url).then(function (response) {
          let resp = response.data;
          if (resp.status == "success") {
            var clientSecret = resp.data;
            self.stripe
              .confirmCardSetup(clientSecret, {
                payment_method: {
                  card: self.stripe_card,
                  billing_details: billing_details,
                },
              })
              .then(function (result) {
                if (result.error) {
                  self.error_message = result.error.message;
                  self.in_flight = false;
                } else {
                  self.localValue.payment_method = result.setupIntent.payment_method;
                  self.update_billing();
                }
              });
          } else {
            messageBus.$emit("api-error", resp);
            self.error_message = this.$t("clinic_billing.we_ran_into_a_problem_verifying_your_credit_card_info");
            self.in_flight = false;
          }
        });
      } else {
        self.update_billing();
      }
    },
    update_billing() {
      var update_billing = {
        billing_address_1: this.localValue.billing_address_1,
        billing_address_2: this.localValue.billing_address_2,
        billing_locality: this.localValue.billing_locality,
        billing_district: this.localValue.billing_district,
        billing_postal: this.localValue.billing_postal,
        billing_country: this.localValue.billing_country,
        billing_contact: this.localValue.billing_contact,
        billing_strategy: this.localValue.billing_strategy,
        allow_clinic_billing: this.localValue.allow_clinic_billing,
        promo_code: this.localValue.promo_code,
        payment_preference: this.localValue.payment_preference || "charge_automatically",
        payment_method: this.localValue.payment_method || null,
      };

      axios.post("/api/clinic/" + this.context_object.id + "/billing", update_billing).then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          messageBus.$emit("success", "Billing updated");
          this.saved_payment_preference = self.payment_preference;
          this.reload_clinic();
          this.$emit("input", this.localValue);
          this.$emit("billing-updated", update_billing);
          this.needs_save = false;
        } else {
          if (resp.error_code instanceof Object) {
            if (resp.error_code.CardVerficationFailure) {
              if (resp.error_code.CardVerficationFailure == "cvc") {
                this.localValue.card_on_file = null;
                this.localValue.payment_method = null;
                this.error_message = this.$t("clinic_billing.the_cvc_code_provided_does_not_match");
              } else if (resp.error_code.CardVerficationFailure == "postal_code") {
                this.error_message = this.$t("clinic_billing.the_postal_code_provided_in_the_billing_address_does_not_match");
              }
            }
          }

          messageBus.$emit("api-error", resp);
        }
        this.in_flight = false;
      });
    },
    reload_clinic() {
      // Reload the clinic
      if (this.context == "clinic") {
        axios.get("/api/clinic/" + this.context_object.id).then((response) => {
          if (response.data.status == "success") {
            let clinic = Clinic.fromJson(response.data.data);
            if (this.current_clinic_id == clinic.id) {
              this.$store.commit("setCurrentClinic", clinic);
            }
          }
        });
      }
    },
  },
};
</script>
