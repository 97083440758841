<template>
  <v-card id="user_account_modal">
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close_user_account">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ user.email }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-4">
      <v-container>
        <v-layout v-if="!limit_collection" row wrap justify-space-around>
          <v-flex xs2>
            <v-text-field ref="name_prefix" v-model="local_user.name_prefix" :label="$t('common.title')" type="text" />
          </v-flex>
          <v-flex xs4>
            <v-text-field ref="name_given" v-model="local_user.name_given" :label="$t('common.first_name')" type="text" />
          </v-flex>
          <v-flex xs4>
            <v-text-field ref="name_family" v-model="local_user.name_family" :label="name_family_label" type="text" />
          </v-flex>
          <v-flex xs2>
            <v-text-field ref="name_credentials" v-model="local_user.name_credentials" :label="$t('common.suffix')" type="text" />
          </v-flex>
        </v-layout>

        <v-layout v-else row wrap justify-space-around>
          <v-flex xs6>
            <v-text-field ref="name_given" v-model="local_user.name_given" :label="$t('common.first_name')" type="text" />
          </v-flex>
          <v-flex xs6>
            <v-text-field ref="name_family" v-model="local_user.name_family" :label="name_family_label" type="text" />
          </v-flex>
        </v-layout>

        <v-text-field v-if="current_user.is_admin" v-model="local_user.email" :label="$t('common.email')" />

        <DoublePassword
          v-if="show_update_password"
          ref="update_password"
          v-model="password"
          :label="$t('user_edit.new_password')"
          :confirm-label="$t('user_edit.confirm_new_password')"
          @passwords-match="update_passwords_match"
        />

        <v-text-field
          v-if="show_update_password && (!current_user.is_admin || current_user.id == user.id)"
          :label="$t('user_edit.current_password')"
          type="password"
          :rules="current_password_rules"
        />

        <v-switch
          v-if="show_is_admin && current_user.email == 'patrick.d.hayes@gmail.com'"
          v-model="local_user.is_admin"
          :label="$t('user_edit.super_admin')"
          @change="alert_super_admin_change"
        />
      </v-container>

      <p v-if="referral_code" style="font-weight: light">
        Your clinic’s Referral Code:
        <strong>{{ referral_code }}</strong>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon style="margin-top: -2px" v-bind="attrs" v-on="on" @click="ref_dialog = true">
              <v-icon color="grey" small light>info</v-icon>
            </v-btn>
          </template>
          <span>More Information</span>
        </v-tooltip>

        <!-- Referral Code Dialog -->
        <v-dialog v-model="ref_dialog" max-width="800px">
          <v-card v-if="ref_dialog">
            <v-card-title style="padding: 0px">
              <v-toolbar grey flat>
                <v-btn icon @click="ref_dialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Referral Program</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <ReferralCodeInfo :clinic="current_clinic" :include_title="false" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </p>

      <div v-if="user_subscriptions && user_subscriptions.length > 0">
        <h3>Subscriptions</h3>
        <div v-for="(stripe_sub, stripe_sub_idx) in stripe_subscriptions" :key="stripe_sub_idx">
          <v-card v-if="stripe_sub.status != 'incomplete' && stripe_sub.status != 'incomplete_expired'" tile class="mt-3">
            <v-card-title v-if="stripe_sub.description" class="mb-n6 ml-n1" style="font-size: 1.1em">
              {{ stripe_sub.description }}
            </v-card-title>
            <v-card-text class="pa-6 pb-5">
              <v-layout row justify-space-between>
                <v-flex xs4>
                  <strong>{{ $t("common." + stripe_sub.status) }}</strong>
                </v-flex>
                <v-flex xs4>
                  <span v-if="stripe_sub.status == 'active'">
                    {{ stripe_sub_price(stripe_sub) }}
                    {{ stripe_sub_currency(stripe_sub) }} /
                    {{ $t("common." + stripe_sub_interval(stripe_sub)) }}
                  </span>
                </v-flex>
                <v-flex xs4 class="text-right">
                  <v-btn small :href="stripe_user_portal_href" target="_blank">
                    <span v-if="stripe_sub.status == 'active'">
                      {{ $t("user_edit.manage_subscription") }}
                    </span>
                    <span v-else>
                      {{ $t("user_edit.view_invoices") }}
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-btn v-if="show_logout" :disabled="logout_in_progress" small @click="logout">
        <v-icon small left>fa-sign-out-alt</v-icon>
        {{ $t("common.log_out") }}
      </v-btn>
      <v-btn small @click="show_update_password = true">
        <v-icon small left>fa-unlock</v-icon>
        {{ $t("user_edit.update_password") }}
      </v-btn>
      <!--
      <v-btn small>
        <v-icon small left>email</v-icon>
        {{ $t("user_edit.email_preferences") }}
      </v-btn>
      -->
      <v-spacer />
      <v-alert v-if="error_message != ''" dense outlined :value="error_message != ''" type="error">{{ error_message }}</v-alert>
      <v-spacer />
      <v-btn color="primary" @click="submit()">{{ $t("common.save_changes") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import axios from "axios";
import DoublePassword from "./DoublePassword";
import ReferralCodeInfo from "./ReferralCodeInfo";
import { mapState } from "vuex";
import { logout_helpscout } from "../lib/helpscout";

export default {
  components: { DoublePassword, ReferralCodeInfo },
  props: {
    user: {
      type: Object,
      required: true,
    },
    show_logout: {
      type: Boolean,
      default: false,
    },
    show_is_admin: {
      type: Boolean,
      default: false,
    },
    need_old_password: {
      type: Boolean,
      default: false,
    },
    limit_collection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let data = {
      local_user: Object.assign({}, this.user),
      password: "",
      current_password: "",
      current_password_rules: [],
      show_current_password: false,
      in_flight: false,
      passwords_match: false,
      error_message: "",
      logout_in_progress: false,
      ref_dialog: false,
      show_update_password: false,
      stripe_subscriptions: [],
    };
    return data;
  },
  computed: {
    ...mapState(["current_clinic", "current_user", "user_clinics", "masquerade", "user_subscriptions"]),
    referral_code() {
      if (this.current_clinic) {
        for (var i in this.user_clinics) {
          let user_clinic = this.user_clinics[i];
          let user_role = user_clinic.user_role;
          if (user_clinic.id == this.current_clinic.id && (user_role == "admin" || user_role == "staff")) {
            return this.current_clinic.tag_value("ref_code");
          }
        }
      }
      return "";
    },
    name_family_label() {
      if (this.limit_collection) {
        return this.$t("user_edit.last_name");
      } else {
        return this.$t("common.last_name");
      }
    },
    stripe_user_portal_href() {
      return "/api/subscription/portal/user/" + this.current_user.id;
    },
  },
  watch: {
    user() {
      this.local_user = Object.assign({}, this.user);
    },
  },
  mounted() {
    if (this.user_subscriptions && this.user_subscriptions.length > 0) {
      axios.get("/api/stripe/user_subscriptions").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.stripe_subscriptions = resp.data;

          // Sort by active status, then by date
          this.stripe_subscriptions.sort((a, b) => {
            if (a.status == "active" && b.status != "active") {
              return -1;
            } else if (a.status != "active" && b.status == "active") {
              return 1;
            } else {
              return b.created - a.created;
            }
          });
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    }
  },
  methods: {
    alert_super_admin_change() {
      alert("NOTICE: changing super-user status");
    },
    close_user_account: function () {
      this.password = ""; // TODO: This is not working..
      this.current_password = "";
      this.local_user = Object.assign({}, this.user);
      if (this.$refs.update_password) {
        this.$refs.update_password.reset();
      }
      this.show_current_password = false;
      this.$emit("close-user-account");
    },
    update_passwords_match: function (event_value) {
      this.passwords_match = event_value;
    },
    submit: function () {
      let self = this;
      self.error_message = "";

      if (this.password && !this.passwords_match) {
        self.error_message = this.$t("user_edit.confirmation_new_password_not_match");
        this.$refs.update_password.focus_confirm(true);
        return;
      }

      if (this.password && !this.current_password && !this.current_user.is_admin) {
        self.error_message = this.$t("user_edit.input_current_password");
        return;
      }

      self.in_flight = true;

      axios.put("/api/user/" + self.user.id, self.local_user).then(function (response) {
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
          self.in_flight = false;
        } else {
          // Update the password if needed
          if (self.password) {
            axios
              .post("/api/user/" + self.user.id + "/update_password", {
                new_password: self.password,
                current_password: self.current_password,
              })
              .then(function (response) {
                self.in_flight = false;
                let resp = response.data;
                if (resp.status == "success") {
                  self.success();
                } else {
                  if (resp.error_code == "InvalidPassword") {
                    self.error_message = self.$t("common.invalid_password_try_again");
                    self.current_password = "";
                  } else {
                    messageBus.$emit("api-error", resp);
                  }
                }
              });
          } else {
            self.success();
          }
        }
      });
    },
    success: function () {
      this.in_flight = false;
      messageBus.$emit("success", this.$t("user_edit.updated_account_information"));
      messageBus.$emit("user-updated", this.local_user);
      this.$emit("user-updated", this.local_user);
      this.close_user_account();
    },
    logout: function () {
      this.logout_in_progress = true;
      logout_helpscout();

      if (this.masquerade) {
        axios.post("/api/user/unmasquerade").then(function (response) {
          let resp = response.data;
          if (resp.status == "success") {
            window.location.reload();
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
      } else {
        axios.post("/api/user/logout").then(function (response) {
          let resp = response.data;
          if (resp.status == "success") {
            window.location.reload();
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
      }
    },
    stripe_sub_plan(stripe_sub) {
      if (stripe_sub.items && stripe_sub.items.data && stripe_sub.items.data.length > 0) {
        return stripe_sub.items.data[0].plan;
      }
      return null;
    },
    stripe_sub_interval(stripe_sub) {
      if (this.stripe_sub_plan(stripe_sub)) {
        return this.stripe_sub_plan(stripe_sub).interval;
      }
      return "";
    },
    stripe_sub_currency(stripe_sub) {
      if (this.stripe_sub_plan(stripe_sub)) {
        return this.stripe_sub_plan(stripe_sub).currency.toUpperCase();
      }
      return "";
    },
    stripe_sub_price(stripe_sub) {
      let price = null;

      if (this.stripe_sub_plan(stripe_sub)) {
        price = this.stripe_sub_plan(stripe_sub).amount / 100;
      }

      if (stripe_sub.discount && stripe_sub.discount.coupon && stripe_sub.discount.coupon.valid) {
        if (stripe_sub.discount.coupon.amount_off) {
          price -= stripe_sub.discount.coupon.amount_off / 100;
        } else if (stripe_sub.discount.coupon.percent_off) {
          price *= 1 - stripe_sub.discount.coupon.percent_off / 100;
        }

        // Round price
        price = Math.round(price * 100) / 100;
      }

      return price;
    },
  },
};
</script>

<style>
#user_account_modal .v-messages__message {
  line-height: normal;
}
</style>
