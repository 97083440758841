<template>
  <div>
    <div v-if="optionsTexts.length == 0">
      <v-progress-circular indeterminate color="primary" />
      <div style="font-size: smaller">
        {{ $t("select_text.loading_reading_list") }}
      </div>
    </div>

    <v-container v-else>
      <v-layout row wrap justify-space-between>
        <v-flex xs3>
          <v-select v-model="lang" outlined :label="$t('common.language')" :items="optionsLanguage" />
        </v-flex>
        <v-flex xs2>
          <v-select v-model="reading_level" outlined :label="$t('common.category')" :items="optionsLevels">
            <template slot="item" slot-scope="item">
              <v-list-item-content style="max-width: 500px">
                <v-list-item-title v-html="item.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="item.item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs4>
          <v-select v-model="localValue" outlined :label="label" :hint="$t('select_text.select_text')" :items="optionsTexts" @change="$emit('change', value)">
            <template slot="item" slot-scope="item">
              <v-list-item-content>
                <v-list-item-title v-html="item.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="item.item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs2>
          <v-btn
            style="margin-top: 12px"
            @click="
              localValue = 'custom';
              $emit('change', 'custom');
            "
            >{{ $t("select_text.custom_text") }}</v-btn
          >
        </v-flex>
      </v-layout>

      <v-textarea
        v-if="custom_selection"
        v-model="custom_text"
        :title="$t('select_text.custom_text')"
        :hint="$t('select_text.type_or_paste_custom_text')"
        maxlength="12000"
      />
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import lzbase62 from "lzbase62";
import lang_codes from "../../../data/lang_codes.json";
import Vue from "vue";
// import Parser from "rss-parser";
import Parser from "rss-parser/dist/rss-parser";

export default {
  components: {},
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: function () {
        return this.$t("select_text.text");
      },
    },
    include_news_articles: {
      type: Boolean,
      default: () => false,
    },
    default_reading_level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    let data = {
      custom_selection: false,
      upload_url: "",
      upload_title: "",
      upload_error: "",
      custom_text: "",
      files: [],
      lang: this.$i18n.lang(),
      reading_level: this.default_reading_level,
      allTexts: [],
      conversation_items: {},
      globalvoices_items: {},
    };

    // If we have a language selected, remember it
    let stored_lang = window.localStorage.getItem("opticalgym_select_text_lang");
    if (stored_lang) {
      data.lang = stored_lang;
    }

    return data;
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        if (localValue == "custom") {
          this.custom_selection = true;

          if (this.custom_text) {
            // make a dataurl
            let compressed = lzbase62.compress(this.custom_text);
            var custom_url = "data:text/plain;base64," + compressed;
            this.$emit("input", custom_url);
          } else if (this.upload_url !== "") {
            this.$emit("input", this.upload_url);
          } else {
            this.$emit("input", "custom");
          }
        } else {
          this.custom_selection = false;
          this.$emit("input", localValue);
        }
      },
    },

    optionsLanguage() {
      let languages = [];
      for (var i in this.allTexts) {
        let text = this.allTexts[i];
        for (var j in this.levels) {
          if (this.languages[j].value == text.lang) {
            continue;
          }
        }
        let lang_name = "Unknown";
        if (lang_codes[text.lang]) {
          lang_name = lang_codes[text.lang].nativeName;
        }

        languages.push({
          text: lang_name,
          value: text.lang,
        });
      }

      // Manually include langauges with feeds if not already present
      if (this.include_news_articles) {
        for (let lang_code in lang_codes) {
          let lang_info = lang_codes[lang_code];

          if (lang_info["feeds"] && lang_info["feeds"].length > 0) {
            let lang_exists = false;
            for (let l of languages) {
              if (l.value == lang_code) {
                lang_exists = true;
                break;
              }
            }
            if (!lang_exists) {
              languages.push({
                text: lang_info.nativeName,
                value: lang_code,
              });
            }
          }
        }
      }

      return languages;
    },
    optionsLevels() {
      let levels = [];
      for (var i in this.allTexts) {
        let text = this.allTexts[i];
        let level = text.grade;
        if (level > 13) {
          level = 13;
        }
        for (var j in this.levels) {
          if (this.levels[j].value == level) {
            continue;
          }
        }
        if (text.lang == this.lang || !text.lang) {
          levels.push({
            text: this.$t("common.level") + " " + level + (level == 13 ? "+" : ""),
            value: level,
          });
        }
      }
      if (this.include_news_articles) {
        let lang_info = lang_codes[this.lang];
        if (lang_info["feeds"] && lang_info["feeds"].includes("theconversation")) {
          levels.push({
            text: this.$t("select_text.news_from_the_conversation"),
            value: "theconversation_" + this.lang,
            subtitle: this.$t("select_text.the_conversation_subtext"),
          });
        }
        if (lang_info["feeds"] && lang_info["feeds"].includes("globalvoices")) {
          levels.push({
            text: this.$t("select_text.news_from_global_voices"),
            value: "globalvoices_" + this.lang,
            subtitle: this.$t("select_text.global_voices_subtext"),
          });
        }
      }

      return levels;
    },

    optionsTexts() {
      let texts = [];
      for (var i in this.allTexts) {
        let text = this.allTexts[i];
        let level = text.grade;
        if (level > 13) {
          level = 13;
        }
        if (level == this.reading_level && (text.lang == this.lang || !text.lang)) {
          texts.push({
            text: text.title,
            value: text.id,
          });
        }
      }

      if (this.reading_level == "theconversation_" + this.lang && this.conversation_items[this.lang]) {
        for (var item of this.conversation_items[this.lang]) {
          // Skip podcasts and anything political
          if (
            item.title.includes("podcast") ||
            item.summary.includes("podcast") ||
            (item.title.toLowerCase().includes("israel") && item.title.toLowerCase().includes("gaza")) ||
            item.title.toLowerCase().includes("sex") ||
            item.title.toLowerCase().includes("mutilation") ||
            item.title.toLowerCase().includes("trump")
          ) {
            continue;
          }

          let date = new Date(item.pubDate.substr(0, 10));
          let formatted_date = date.toLocaleString(this.$i18n.lang(), {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

          let author = item.author;
          if (author.length > 120) {
            author = author.substr(0, 120) + "...";
          }

          texts.push({
            text: item.title,
            value: "feed:theconversation_" + this.lang + ":" + item.id,
            subtitle: formatted_date + " - by " + author,
          });
        }
      }

      if (this.reading_level == "globalvoices_" + this.lang && this.globalvoices_items[this.lang]) {
        for (var item of this.globalvoices_items[this.lang]) {
          // Skip podcasts and anything political
          if (
            (item.title.toLowerCase().includes("israel") && item.title.toLowerCase().includes("gaza")) ||
            item.title.toLowerCase().includes("sex") ||
            item.title.toLowerCase().includes("mutilation") ||
            item.title.toLowerCase().includes("trump")
          ) {
            continue;
          }

          let date = new Date(item.isoDate.substr(0, 10));
          let formatted_date = date.toLocaleString(this.$i18n.lang(), {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

          let author = item.creator;
          if (author.length > 120) {
            author = author.substr(0, 120) + "...";
          }

          texts.push({
            text: item.title,
            value: "feed:globalvoices_" + this.lang + ":" + item.link,
            subtitle: formatted_date + " - by " + author,
          });
        }
      }

      if (texts.length > 0) {
        texts.push({
          text: this.$t("common.custom"),
          value: "custom",
        });
      }

      return texts;
    },
    post_location: function () {
      return "/api/upload-text/" + this.upload_title;
    },
  },
  watch: {
    lang(lang) {
      this.load_feed_texts();
      this.auto_select_level_and_text();

      window.localStorage.setItem("opticalgym_select_text_lang", lang);
    },
    reading_level() {
      if (this.optionsTexts.length) {
        this.localValue = this.optionsTexts[0].value;
      }
    },
    custom_text() {
      if (this.custom_text) {
        // make a dataurl
        let compressed = lzbase62.compress(this.custom_text);
        var custom_url = "data:text/plain;base64," + compressed;
        this.$emit("input", custom_url);
      }
    },
  },
  mounted() {
    this.load_texts();
  },
  methods: {
    ensure_valid_value() {
      // Check if localValue is valid, if not, then reset it to the first valid option
      if (this.localValue && (this.localValue.startsWith("feed:") || this.localValue == "custom" || this.localValue.startsWith("data:"))) {
        return;
      }

      if (this.optionsTexts.length == 0) {
        return;
      }

      if (this.localValue === undefined || this.localValue === null || this.localValue === "") {
        this.localValue = this.optionsTexts[0].value;
        Vue.nextTick(() => {
          this.$emit("input", this.localValue);
        });
        return;
      }

      let text_exists = false;
      for (var i in this.optionsTexts) {
        if (this.optionsTexts[i].value == this.localValue) {
          text_exists = true;
          break;
        }
      }
      if (!text_exists) {
        this.localValue = this.optionsTexts[0].value;
        Vue.nextTick(() => {
          this.$emit("input", this.localValue);
        });
      }
    },

    auto_select_level_and_text() {
      if (this.optionsTexts.length) {
        this.localValue = this.optionsTexts[0].value;
      }

      let level_exists = false;
      for (var i in this.optionsLevels) {
        if (this.reading_level == this.optionsLevels[i].value) {
          level_exists = true;
          break;
        }
      }

      if (!level_exists) {
        this.reading_level = this.optionsLevels[0].value;
      }
    },

    load_feed_texts() {
      if (!this.include_news_articles) {
        return;
      }

      let lang_code = this.lang;

      if (lang_codes[lang_code] && lang_codes[lang_code]["feeds"] && lang_codes[lang_code]["feeds"].includes("theconversation")) {
        let url = "/api/reading_feed/theconversation_" + lang_code;

        let parser = new Parser();
        parser.parseURL(url).then((feed) => {
          // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
          feed.items.pop();
          feed.items.pop();

          Vue.set(this.conversation_items, lang_code, feed.items);

          this.auto_select_level_and_text();
        });
      }

      if (lang_codes[lang_code] && lang_codes[lang_code]["feeds"] && lang_codes[lang_code]["feeds"].includes("globalvoices")) {
        let url = "/api/reading_feed/globalvoices_" + lang_code;

        let parser = new Parser();
        parser.parseURL(url).then((feed) => {
          // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
          feed.items.pop();
          feed.items.pop();

          Vue.set(this.globalvoices_items, lang_code, feed.items);

          this.auto_select_level_and_text();
        });
      }
    },
    load_texts: function () {
      // Load texts from backend
      axios.get("/api/reading_text").then((response) => {
        let texts = response.data.data;
        this.allTexts = texts;

        // If the currently selected text is not valid, then default to the first one
        Vue.nextTick(() => {
          this.ensure_valid_value();

          // Load feed texts
          this.load_feed_texts();
        });
      });
    },
    force_select_reading_level() {
      // There might be a situation here where we don't have any level selected because the user has selected a language that doesn't have any levels.
      let level_exists = false;
      for (var i in this.optionsLevels) {
        if (this.reading_level == this.optionsLevels[i].value) {
          level_exists = true;
          break;
        }
      }
      if (!level_exists) {
        this.reading_level = this.optionsLevels[0].value;
      }
    },
    inputFile: function (newFile, oldFile) {
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.upload_error = ""; // Reset any previous errors
          this.$refs.upload.active = true;
        }
      }

      if (newFile && oldFile) {
        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          if (newFile.response.status == "error") {
            this.upload_error = newFile.response.error;
          }
          if (newFile.response.status == "ok") {
            this.upload_url = newFile.response.url;
            this.$emit("input", this.upload_url);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.file-upload-area {
  text-align: center !important;
  padding: 3rem !important;
}

label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
