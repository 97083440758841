import RootRoute from "./routes/RootRoute";
import ExerciseDemoRoute from "./routes/ExerciseDemoRoute";
import VideoDemoRoute from "./routes/VideoDemoRoute";
import PasswordResetRoute from "./routes/PasswordRestRoute";
import AdminClinicsRoute from "./routes/AdminClinicsRoute";
import AdminUsersRoute from "./routes/AdminUsersRoute";
import AdminChargesRoute from "./routes/AdminChargesRoute";
import AdminMonthlyReportsRoute from "./routes/AdminMonthlyReportsRoute";
import ClinicRoute from "./routes/ClinicRoute";
import ToolsRoute from "./routes/ToolsRoute";
import PrintablesRoute from "./routes/PrintablesRoute";
import ClinicSignUpRoute from "./routes/ClinicSignUpRoute";
import AcceptInviteRoute from "./routes/AcceptInviteRoute";
import EquipmentRoute from "./routes/EquipmentRoute";
import AdminClinicRoute from "./routes/AdminClinicRoute";
import AdminUserRoute from "./routes/AdminUserRoute";
import AdminRevisionsRoute from "./routes/AdminRevisionsRoute";
import BillingRoute from "./routes/BillingRoute";
import UserSignupRoute from "./routes/UserSignupRoute";
import ProgressReportRoute from "./routes/ProgressReportRoute";
import GridHistory from "./routes/GridHistory";
import AssessmentRoute from "./routes/AssessmentRoute";
import AssessmentReportRoute from "./routes/AssessmentReportRoute";
import GridSetRoute from "./routes/GridSetRoute";
import NewSubscriptionRoute from "./routes/NewSubscriptionRoute";

const routes = [
  { path: "", component: RootRoute },
  { path: "/", component: RootRoute },
  { path: "/app", component: RootRoute },
  { path: "/app/exercises", component: ExerciseDemoRoute },
  { path: "/app/equipment", component: EquipmentRoute },
  { path: "/app/videos", component: VideoDemoRoute },
  { path: "/app/password_reset", component: PasswordResetRoute },
  { path: "/app/tools", component: ToolsRoute },
  { path: "/app/printables", component: PrintablesRoute },
  { path: "/app/progress_report", component: ProgressReportRoute },
  { path: "/app/history", component: GridHistory },
  { path: "/app/clinic/:id", component: ClinicRoute },
  { path: "/app/clinic/:id/billing", component: ClinicRoute },
  { path: "/app/clinic/:id/grids", component: ClinicRoute },
  { path: "/app/billing", component: BillingRoute },
  { path: "/app/assessment/:assessment_id/:clinic_id/:invite_id", component: AssessmentRoute },
  { path: "/app/code/:token", component: AcceptInviteRoute },
  { path: "/app/code", component: AcceptInviteRoute },
  { path: "/app/signup", component: ClinicSignUpRoute },
  { path: "/app/signup-user", component: UserSignupRoute },
  { path: "/app/grid_set/:id", component: GridSetRoute },
  { path: "/app/signup-user/:token", component: UserSignupRoute },
  { path: "/app/admin/clinics", component: AdminClinicsRoute },
  { path: "/app/admin/users", component: AdminUsersRoute },
  { path: "/app/admin/charges", component: AdminChargesRoute },
  { path: "/app/admin/revisions", component: AdminRevisionsRoute },
  { path: "/app/admin/revisions/:id", component: AdminRevisionsRoute },
  { path: "/app/admin/monthly", component: AdminMonthlyReportsRoute },
  { path: "/app/admin/clinic/:id", component: AdminClinicRoute },
  { path: "/app/admin/user/:id", component: AdminUserRoute },
  { path: "/app/new_subscription/:clinic_id", component: NewSubscriptionRoute },
  { path: "/app/assessment_report/:assessment_id/:invite_id/:assessment_instance_id", component: AssessmentReportRoute },
];

export default routes;
