import Vue from "vue";
import Vuex from "vuex";
import { Calibration, CalibrationList, UserCalibrationMetadata } from "./classes/calibration";
import User from "./classes/user";
import Clinic from "./classes/clinic";
import Invite from "./classes/invite";
import Exercise from "./classes/exercise";
import VideoExercise, { ClinicVideoExercise } from "./classes/video_exercise";
import GridSet from "./classes/grid_set";
import GridSchedule from "./classes/grid_schedule";
import Report from "./classes/report";
import { Selection, SelectionList } from "./classes/selection";
import UserMetadata from "./classes/user_metadata";
import AssignedStaff from "./classes/assigned_staff";
import IPCountry from "./classes/ip_country";
import axios from "axios";
import { AppConfig, default_app_config } from "./plugins/app_config";
import ReadingText from "./classes/reading_text";
import File from "./classes/file";
import Regimen from "./classes/regimen";
import Subscription from "./classes/subscription";
import * as Sentry from "@sentry/browser";
import cookie from "js-cookie";
import i18n from "./i18n";

Vue.use(Vuex);

function is_mobile(): boolean {
  // Old iPad
  if (window.navigator.platform.includes("iPad")) {
    return true;
  }

  // Mobile touch device that is not windows. This is a bit of a hack, but it works.
  else if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && !/Win/.test(navigator.platform)) {
    return true;
  } else {
    return false;
  }
}

function rolePrecedence(role: string | null): number {
  if (!role) {
    return 0;
  }
  switch (role) {
    case "admin":
      return 3;
    case "staff":
      return 2;
    case "user":
      return 1;
    default:
      return 0; // Default to lowest precedence
  }
};

// State types
export interface State {
  user_calibrations: CalibrationList;
  user_metadata_loading: boolean;
  current_calibration: Calibration | null;
  current_user: User | null;
  current_clinic_id: string;
  current_clinic: Clinic | null;
  current_clinic_role: string | null;
  current_clinic_selections: SelectionList | null;
  current_invite: Invite | null;
  calibration_dialog: boolean;
  vuex_error_message: string;
  vuex_has_error_message: boolean;
  user_metadata: UserMetadata[];
  user_clinics: Clinic[];
  masquerade: boolean;
  user_invites: Invite[];
  user_regimens: Regimen[];
  user_subscriptions: Subscription[];
  assigned_staff: AssignedStaff[];
  ip_country: null | IPCountry;
  gcloud_file_bucket: string;
  stripe_public_key: string;
  file_base_url: string;
  highest_perm: string;
  app_config: AppConfig;
  exercises: { [key: string]: Exercise } | null;
  video_exercises: { [key: string]: VideoExercise } | null;
  small_screen: boolean;
  is_mobile: boolean;
  grid_sets: GridSet[];
  grid_schedules: GridSchedule[];
  reading_texts: ReadingText[];
  clinic_video_exercises: ClinicVideoExercise[];
  clinic_printables: File[];
  free_trial_grid_schedules: string[];
  admin_show_ids: boolean;
  admin_show_english: boolean;
  admin_show_english_backup_locale: string;
  admin_show_i18n_path: boolean;
  print_mode: boolean;
  current_progress_reports: Report[] | null;
}

// Default state
let query_params = new URLSearchParams(window.location.search);

const state: State = {
  user_calibrations: new CalibrationList(),
  user_metadata_loading: false,
  current_calibration: null,
  current_user: null,
  current_clinic_id: "",
  current_clinic: null,
  current_clinic_role: null,
  current_clinic_selections: null,
  current_invite: null,
  current_progress_reports: null,
  calibration_dialog: false,
  vuex_error_message: "",
  vuex_has_error_message: false,
  user_clinics: [],
  user_metadata: [],
  masquerade: false,
  user_invites: [],
  user_regimens: [],
  user_subscriptions: [],
  assigned_staff: [],
  ip_country: null,
  gcloud_file_bucket: "",
  stripe_public_key: "",
  file_base_url: "",
  highest_perm: "",
  app_config: window.app_config ? window.app_config : default_app_config,
  exercises: null,
  video_exercises: null,
  small_screen: window.innerWidth <= 1300 || window.innerHeight <= 700,
  is_mobile: is_mobile(),
  grid_sets: [],
  grid_schedules: [],
  reading_texts: [],
  clinic_video_exercises: [],
  clinic_printables: [],
  free_trial_grid_schedules: [],
  admin_show_ids: false,
  admin_show_english: false,
  admin_show_i18n_path: false,
  admin_show_english_backup_locale: "en",
  print_mode: query_params.get("print") == "true",
};

export default new Vuex.Store({
  state: state,

  getters: {
    userCalibrations: (state) => state.user_calibrations,
    currentUser: (state) => state.current_user,
    currentCalibration: (state) => state.current_calibration,
    calibrationDialog: (state) => state.calibration_dialog,
  },

  mutations: {
    setProgressReports(state: State, reports: Report[]) {
      state.current_progress_reports = reports;
    },
    userMetadataLoading(state: State, loadingStatus: boolean) {
      state.user_metadata_loading = loadingStatus;
    },
    setCalibrations(state: State, calibration_list: CalibrationList) {
      state.user_calibrations = calibration_list;
      state.current_calibration = calibration_list.getCurrentCalibration();
    },
    pushCalibration(state: State, calibartion: Calibration) {
      state.user_calibrations.data.push(calibartion);
    },
    updateCalibration(state: State, calibration: Calibration) {
      for (let i in state.user_calibrations.data) {
        if (state.user_calibrations.data[i].id == calibration.id) {
          state.user_calibrations.data[i] = calibration;
        }
      }
    },
    setCurrentCalibration(state: State, current_calibration: Calibration) {
      state.current_calibration = current_calibration;
    },
    setCurrentUser(state: State, current_user: User) {
      state.current_user = current_user;

      Sentry.setUser({ id: current_user.id, email: current_user.email });

      // If they are admin, intercept key presses to show the admin info
      if (current_user.is_admin) {
        document.onkeyup = (e) => {
          if (e.target && e.target instanceof Element) {
            if (e.target.tagName == "INPUT" || e.target.tagName == "TEXTAREA") {
              return;
            }
          }
          // If they are holding shift and the event key is z, display IDs
          if (e.shiftKey && (e.key == "Z" || e.key == "z")) {
            state.admin_show_ids = !state.admin_show_ids;
          }

          // If they are holding shift and the event key is x, display English regardless of language
          if (e.shiftKey && (e.key == "X" || e.key == "x")) {
            state.admin_show_english = !state.admin_show_english;

            // Set the locale to English to display englsih text
            if (state.admin_show_english) {
              state.admin_show_english_backup_locale = i18n.locale;
              i18n.locale = "en";
            } else {
              i18n.locale = state.admin_show_english_backup_locale;
            }
          }

          // If they are holding shift and the event key is c, display i18n paths
          // TODO: Make this work when upgrading to Vue 3, needs support from "vue-i18n" 'messageResolver' option
          if (e.shiftKey && (e.key == "C" || e.key == "c")) {
            state.admin_show_i18n_path = !state.admin_show_i18n_path;
          }
        };
      }
    },
    setCalibrationDialog(state: State, dialog_state: boolean) {
      state.calibration_dialog = dialog_state;
    },
    setErrorMessage(state: State, error_message: string) {
      state.vuex_error_message = error_message;

      // TODO: Loggly
      if (error_message) {
        state.vuex_has_error_message = true;
      } else {
        state.vuex_has_error_message = false;
      }
    },
    setHasErrorMessage(state: State, has_error: boolean) {
      state.vuex_has_error_message = has_error;
    },
    setUserClinics(state: State, clinics: Clinic[]) {
      state.user_clinics = clinics;
    },
    setMasquerade(state: State, masquerade: boolean) {
      state.masquerade = masquerade;
    },
    setUserInvites(state: State, invites: Invite[]) {
      state.user_invites = invites;
    },
    setUserRegimens(state: State, regimens: Regimen[]) {
      state.user_regimens = regimens;
    },
    setUserSubscriptions(state: State, subscriptions: Subscription[]) {
      state.user_subscriptions = subscriptions;
    },
    setAssignedStaff(state: State, assigned_staff: AssignedStaff[]) {
      state.assigned_staff = assigned_staff;
    },
    setIPCountry(state: State, ip_country: IPCountry) {
      state.ip_country = ip_country;
    },
    setGCloudFileBucket(state: State, gcloud_file_bucket: string) {
      state.gcloud_file_bucket = gcloud_file_bucket;
      state.file_base_url = "https://" + gcloud_file_bucket + ".storage.googleapis.com/";
    },
    setStripePublicKey(state: State, stripe_public_key: string) {
      state.stripe_public_key = stripe_public_key;
    },
    setFreeTrialGridSchedules(state: State, free_trial_grid_schedules: string[]) {
      state.free_trial_grid_schedules = free_trial_grid_schedules;
    },
    setCurrentClinic(state: State, current_clinic: Clinic) {
      state.current_clinic = current_clinic;
      if (current_clinic.id) {
        state.current_clinic_id = current_clinic.id;
        let prefs = cookie.getJSON("user_preferences");
        if (!prefs) {
          prefs = {};
        }
        prefs.current_clinic_id = current_clinic.id;
        cookie.set("user_preferences", prefs, { expires: 365 });
      }

      // Update the current_clinic_role and current_invite
      state.current_clinic_role = "user"
      if (state.current_user && state.current_user.is_admin) {
        state.current_clinic_role = "admin";
      }
      else if (state.current_clinic && state.user_clinics?.length) {      
        for (let user_clinic of state.user_clinics) {
          if (
            user_clinic.id === state.current_clinic.id &&
            rolePrecedence(user_clinic.user_role) > rolePrecedence(state.current_clinic_role)
          ) {
            state.current_clinic_role = user_clinic.user_role;
          }
        }
      }

      // Update the current_invite
      state.current_invite = null;
      if (state.current_clinic && state.user_invites?.length) {
        for (let invite of state.user_invites) {
          if (invite.clinic_id === state.current_clinic.id) {
            if (
              !state.current_invite || 
              rolePrecedence(invite.user_role) > rolePrecedence(state.current_invite.user_role)
            ) {
              state.current_invite = invite;
            }
          }
        }
      }
    },
    setCurrentInvite(state: State, invite: Invite) {
      state.current_invite = invite;
    },
    setCurrentClinicRole(state: State, role: string) {
      state.current_clinic_role = role;
    },
    setCurrentClinicId(state: State, id: string) {
      state.current_clinic_id = id;
      let prefs = cookie.getJSON("user_preferences");
      if (!prefs) {
        prefs = {};
      }
      prefs.current_clinic_id = id;
      cookie.set("user_preferences", prefs, { expires: 365 });
    },
    setCurrentClinicSelections(state: State, selections: SelectionList) {
      state.current_clinic_selections = selections;
    },
    upsertSelection(state: State, selection: Selection) {
      if (selection.clinic_id == state.current_clinic_id) {
        if (!state.current_clinic_selections) {
          state.current_clinic_selections = new SelectionList();
        }
        state.current_clinic_selections.upsert(selection);
      }
    },
    setUserMetadata(state: State, metadata_list: UserMetadata[]) {
      state.user_metadata = metadata_list;
    },
    setHighestPerm(state: State, highest_perm: string) {
      state.highest_perm = highest_perm;
    },
    setExercises(state: State, exercises: { [key: string]: Exercise }) {
      state.exercises = exercises;
    },
    setVideoExercises(state: State, video_exercises: { [key: string]: VideoExercise }) {
      state.video_exercises = video_exercises;
    },
    setGridSets(state: State, grid_sets: GridSet[]) {
      state.grid_sets = grid_sets;
    },
    setGridSchedules(state: State, grid_schedules: GridSchedule[]) {
      state.grid_schedules = grid_schedules;
    },
    setReadingTexts(state: State, reading_texts: ReadingText[]) {
      state.reading_texts = reading_texts;
    },
    setClinicVideoExercises(state: State, clinic_video_exercises: ClinicVideoExercise[]) {
      state.clinic_video_exercises = clinic_video_exercises;
    },
    setClinicPrintables(state: State, clinic_printables: File[]) {
      state.clinic_printables = clinic_printables;
    },
    setSmallScreen(state: State, small_screen: boolean) {
      state.small_screen = small_screen;
    },
  },

  actions: {
    loadClinicPrintables({ commit, state }, callback: Function | undefined) {
      axios.get("/api/clinic/" + state.current_clinic_id + "/files/printable").then(function (response) {
        if (response?.data?.status == "success") {
          let printables = response.data.data;

          // Order printables by title
          printables.sort((a: File, b: File) => (a.title > b.title ? 1 : -1));

          commit("setClinicPrintables", printables);
          if (callback) {
            callback();
          }
        } else {
          window.messageBus.$emit("error", response?.data?.error_message || "Error fetching printable sheets");
        }
      });
    },
    loadClinicVideoExercises({ commit, state }, callback: Function | undefined) {
      axios.get("/api/clinic/" + state.current_clinic_id + "/video_exercises").then(function (response) {
        if (response?.data?.status == "success") {
          let videos = response.data.data;

          // Order videos by title
          videos.sort((a: ClinicVideoExercise, b: ClinicVideoExercise) => (a.title > b.title ? 1 : -1));

          commit("setClinicVideoExercises", videos);
          if (callback) {
            callback();
          }
        } else {
          window.messageBus.$emit("error", response?.data?.error_message || "Error fetching video exercises");
        }
      });
    },
    loadReadingTexts({ commit, state }, callback: Function | undefined) {
      axios.get("/api/clinic/" + state.current_clinic_id + "/reading_texts").then(function (response) {
        if (response?.data?.status == "success") {
          let texts: ReadingText[] = response.data.data;

          // Order texts by grade first and by text title secondarily
          texts.sort((a: ReadingText, b: ReadingText) => {
            if (a.grade == b.grade) {
              return a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1;
            }
            return a.grade > b.grade ? 1 : -1;
          });

          // If there's fulltext data, but no word count, add it
          texts.forEach((text) => {
            if (text.fulltext && !text.words) {
              text.words = text.fulltext.split(" ").length;
            }
          });

          commit("setReadingTexts", texts);
          if (callback) {
            callback();
          }
        } else {
          window.messageBus.$emit("error", response?.data?.error_message || "Error fetching reading texts");
        }
      });
    },
    loadGrids({ commit, state }, callback: Function | undefined) {
      let url = "/api/grid/clinic/" + state.current_clinic_id + "?include_public=true";
      axios.get(url).then(function (response) {
        if (response.data.status == "success") {
          commit("setGridSets", response.data.data.grid_sets);
          commit("setGridSchedules", response.data.data.grid_schedules);
          if (callback) {
            callback();
          }
        }
      });
    },
    loadVideoExercises({ commit, state }, callback: Function | undefined) {
      let url = "/api/video_exercise";
      if (state.current_user && state.current_clinic_id) {
        url += "?clinic_id=" + state.current_clinic_id;
      }
      axios.get(url).then(function (response) {
        if (response?.data?.status == "success") {
          commit("setVideoExercises", response.data.data);
          if (callback) {
            callback();
          }
        } else {
          window.messageBus.$emit("error", response?.data?.error_message || "Error fetching video exercises");
        }
      });
    },
    loadExercises({ commit, state }, callback: Function | undefined) {
      axios.get("/api/exercise").then(function (response) {
        if (response.data.status == "success") {
          commit("setExercises", response.data.data);
          if (callback) {
            callback();
          }
        }
      });
    },
    loadCurrentUser({ commit, state, dispatch }, callback: Function) {
      axios.get("/api/user/current").then(function (response) {
        if (response.data.status == "success") {
          if (response.data.data !== null) {
            let resp = response.data.data;

            let currentUser = User.fromJson(resp.user);

            let user_clinics: Clinic[] = [];
            for (var user_clinic of resp.clinics) {
              user_clinics.push(Clinic.fromJson(user_clinic));
            }

            let user_invites: Invite[] = [];
            for (var user_invite of resp.invites) {
              user_invites.push(Invite.fromJson(user_invite));
            }

            // For each invite, tag the role and invite-id into the clinic
            user_invites.forEach((invite) => {
              user_clinics.forEach((clinic) => {
                if (invite.clinic_id === clinic.id) {
                  if (
                    !clinic.user_role || 
                    rolePrecedence(invite.user_role) > rolePrecedence(clinic.user_role)
                  ) {
                    clinic.user_role = invite.user_role;
                    clinic.invite_id = invite.id;
                  }
                }
              });
            });

            let assigned_staffs = [];
            for (var assigned_staff of resp.assigned) {
              assigned_staffs.push(Clinic.fromJson(assigned_staff));
            }

            let ip_country = IPCountry.fromJson(resp.country);

            commit("setCurrentUser", currentUser);
            commit("setUserClinics", user_clinics);
            commit("setUserInvites", user_invites);
            commit("setUserRegimens", resp.regimens);
            commit("setUserSubscriptions", resp.subscriptions);
            commit("setAssignedStaff", assigned_staffs);
            commit("setIPCountry", ip_country);
            commit("setMasquerade", resp.masquerade);
            commit("setGCloudFileBucket", resp.gcloud_file_bucket);
            commit("setStripePublicKey", resp.stripe_public_key);
            commit("setFreeTrialGridSchedules", resp.free_trial_grid_schedules);

            // Assign the highest ranked clinic as per user-role to the current_clinic_id
            if (user_invites.length) {
              // Calculate the highest permission level
              let highest_perm_clinic_id = user_invites[0].clinic_id;
              let highest_perm = user_invites[0].user_role;
              user_invites.forEach((invite) => {
                if (invite.user_role == "admin") {
                  highest_perm = "admin";
                  highest_perm_clinic_id = invite.clinic_id;
                } else if (invite.user_role == "staff" && highest_perm == "user") {
                  highest_perm = "staff";
                  highest_perm_clinic_id = invite.clinic_id;
                }
              });

              // Check if the user has a preferred current_clinic_id
              let prefs = cookie.getJSON("user_preferences");
              let current_clinic_id = "";
              if (prefs && prefs.current_clinic_id) {
                // Check if preferred current_clinic_id is still valid and in invites
                let found = false;
                for (var invite of user_invites) {
                  if (invite.clinic_id == prefs.current_clinic_id) {
                    found = true;
                    break;
                  }
                }
                if (found) {
                  current_clinic_id = prefs.current_clinic_id;
                }
              }

              // If the current_clinic_id is not set, set it to the highest permission clinic
              if (!current_clinic_id) {
                current_clinic_id = highest_perm_clinic_id;
              }

              // Check the URL for a clinic_id, if it exists then that is preffered over all others
              // Look for the pattern /clinic/<clinic_id> in the URL (there might be trailing data after a /)
              let regex = /\/clinic\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})(\/|$)/;
              let match = window.location.pathname.match(regex);
              if (match) {
                current_clinic_id = match[1];
              }

              // Set the current clinic ID and the highest permission level
              if (current_clinic_id) {
                commit("setCurrentClinicId", current_clinic_id);
              }
              if (highest_perm) {
                commit("setHighestPerm", highest_perm);
              }
            }

            // TODO: load user-metadata as part of the /user/current response
            dispatch("loadUserMetadata");
          }
        } else {
          commit("setErrorMessage", "Error loading current user: " + response.data.error_message);
        }

        if (callback && typeof callback === "function") {
          callback();
        }
      });
    },
    loadProgressReports({ commit, state }, callback: Function | undefined) {
      // Load reports from backend (/invite/<id>/regimen/reports?<start>)
      if (!state.current_invite || state.current_invite.user_role != "user" || !state.app_config) {
        return;
      }

      let invite_reports_url = "/api/invite/" + state.current_invite.id + "/regimen/reports";
      if (state.app_config.regimen_progress_expires == "midnight") {
        // Get midnight in local time
        const now = new Date();
        const midnightLocal = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const isoMidnightNaive = midnightLocal.toISOString().slice(0, 19);

        invite_reports_url += "?start=" + isoMidnightNaive;
      }

      axios.get(invite_reports_url).then(
        (response) => {
          let resp = response.data;
          if (resp.status == "success") {
            commit("setProgressReports", resp.data);
          } else {
            window.messageBus.$emit("error", response.data.error);
          }
          if (callback) {
            callback();
          }
        },
        (error) => {
          window.messageBus.$emit("error", error);
          if (callback) {
            callback();
          }
        }
      );
    },
    loadCurrentClinic({ commit, state, dispatch }, { clinic_id, callback }) {
      let clinic_loaded = false;
      let selections_loaded = false;

      // Load Clinic Info
      axios.get("/api/clinic/" + clinic_id).then(function (response) {
        if (response.data.status == "success") {
          let clinic = Clinic.fromJson(response.data.data);
          commit("setCurrentClinic", clinic);
          clinic_loaded = true;
          if (clinic_loaded && selections_loaded && callback && typeof callback === "function") {
            callback();
          }

          // Reload content
          if (!state.print_mode) {
            dispatch("loadProgressReports");
            dispatch("loadVideoExercises");
            dispatch("loadReadingTexts");
            dispatch("loadClinicPrintables");
          }

          if (state.current_clinic_role == "admin" || state.current_clinic_role == "staff" || (state.current_user && state.current_user.is_admin)) {
            dispatch("loadGrids");
          }
        } else {
          commit("setErrorMessage", "Error loading clinic: " + response.data.error_message);
        }
      });

      // Load clinic selections
      if (!state.print_mode) {
        axios.get("/api/clinic/" + clinic_id + "/selections").then(function (response) {
          if (response.data.status == "success") {
            let selections = SelectionList.fromJson(response.data.data);
            commit("setCurrentClinicSelections", selections);
            selections_loaded = true;
            if (clinic_loaded && selections_loaded && callback && typeof callback === "function") {
              callback();
            }
          } else {
            commit("setErrorMessage", "Error loading clinic selections: " + response.data.error_message);
          }
        });
      } else {
        if (clinic_loaded && callback && typeof callback === "function") {
          callback();
        }
      }
    },

    loadUserCalibrations({ commit }) {
      let user_id = this.state.current_user!.id;
      commit("userMetadataLoading", true);
      axios
        .get("/api/user/" + user_id + "/metadata/calibration")
        .then((response) => {
          commit("userMetadataLoading", false);
          if (response.data.status == "success") {
            commit("setCalibrations", CalibrationList.fromResponse(response.data.data));
          } else {
            commit("setErrorMessage", "Error loading calibrations: " + response.data.error_message);
          }
        })
        .catch((error) => {
          commit("setErrorMessage", "Error loading calibrations: " + error);
        });
    },

    loadUserMetadata({ commit }) {
      let user_id = this.state.current_user!.id;
      axios
        .get("/api/user/" + user_id + "/metadata/*")
        .then((response) => {
          if (response.data.status == "success") {
            let metadata = [];
            let calibrations = [];
            for (let item of response.data.data) {
              metadata.push(UserMetadata.fromJson(item));
              if (item.category == "calibration") {
                calibrations.push(item);
              }
            }
            commit("setUserMetadata", metadata);
            commit("setCalibrations", CalibrationList.fromResponse(calibrations));
          } else {
            commit("setErrorMessage", "Error loading user metadata: " + response.data.error_message);
          }
        })
        .catch((error) => {
          commit("setErrorMessage", "Error loading user metadata: " + error);
        });
    },

    upsertSelection({ commit }, { selection, callback }) {
      if (selection.id) {
        axios
          .put("/api/selection/" + selection.id, selection)
          .then((response) => {
            if (response.data.status == "success") {
              commit("upsertSelection", selection);
            } else {
              commit("setErrorMessage", "Error saving selection: " + response.data.error_message);
            }
            if (callback && typeof callback === "function") {
              callback();
            }
          })
          .catch((error) => {
            commit("setErrorMessage", "Error saving selection: " + error);
          });
      } else {
        axios
          .post("/api/selection", selection)
          .then((response) => {
            if (response.data.status == "success") {
              selection.id = response.data.data;
              commit("upsertSelection", selection);
            } else {
              commit("setErrorMessage", "Error saving selection: " + response.data.error_message);
            }
            if (callback && typeof callback === "function") {
              callback();
            }
          })
          .catch((error) => {
            commit("setErrorMessage", "Error saving selection: " + error);
          });
      }
    },

    updateCurrentCalibration({ commit }, calibration) {
      let curent_user_id = this.state.current_user!.id;
      let do_update = calibration.id && calibration.user_id == curent_user_id;

      let calibration_meta = UserCalibrationMetadata.fromJson({
        id: calibration.id,
        user_id: curent_user_id,
        clinic_id: null,
        category: "calibration",
        data: calibration,
      });
      calibration_meta.data.user_id = curent_user_id;

      if (!do_update) {
        calibration_meta.id = null;
        calibration_meta.data.id = null;
      }

      // TODO: also update the local metadata record that contains this calibration
      //       Maybe we should actually store calibrations totally seperately. Dunno.

      if (do_update) {
        axios
          .put("/api/user_metadata/" + calibration_meta.id, calibration_meta.toJsonObj())
          .then((response) => {
            if (response.data.status == "success") {
              // Nothing, it's good!
              commit("updateCalibration", calibration);
              commit("setCurrentCalibration", calibration);
            } else {
              commit("setErrorMessage", "Error saving calibrations: " + response.data.error_message);
            }
          })
          .catch((error) => {
            commit("setErrorMessage", "Error saving calibrations: " + error);
          });
      } else {
        axios
          .post("/api/user_metadata", calibration_meta.toJsonObj())
          .then((response) => {
            if (response.data.status == "success") {
              calibration.id = response.data.data;
              commit("pushCalibration", calibration);
              commit("setCurrentCalibration", calibration);
            } else {
              commit("setErrorMessage", "Error saving calibrations: " + response.data.error_message);
            }
          })
          .catch((error) => {
            commit("setErrorMessage", "Error saving calibrations: " + error);
          });
      }
    },
  },
});
