import axios from "axios";
import i18n from "../i18n";

var email_checks = {};

export default class OpticalGymUI {
  static validate_email(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  static check_email(email, callback) {
    let email_rules = [];

    if (!OpticalGymUI.validate_email(email)) {
      email_rules = [i18n.t("common.invalid_email_address")];
      callback({
        valid: false,
        hard_no: true,
        email_rules
      })
    }

    if (email_checks[email]) {
      let res = OpticalGymUI.email_check_validate(email_checks[email]);
      callback(res);
    } else {
      axios.post("/api/email/check", { email }).then(function (response) {
        let backend_res = response.data.data;
        email_checks[email] = backend_res;
        let res = OpticalGymUI.email_check_validate(backend_res);
        callback(res);
      });
    }
  }

  static email_check_validate(check) {
    let email_rules = [];
    let parts = check.address.split("@");
    let user = parts[0];
    let host = parts[1];

    for (var i in check.reason) {
      let reason = check.reason[i];

      if (reason == "mailbox_does_not_exist") {
        email_rules.push(
          i18n.t("common.does_not_exist_on", { user: user, domain: host })
        );
      } else if (reason == "no_mx") {
        email_rules.push(
          host + " " + i18n.t("common.does_not_serve_email", {domain: host})
        );
      } else if (reason == "high_risk_domain" || reason == "tld_risk") {
        email_rules.push(
          i18n.t("common.domain_name_used_by_scammers", {domain: host})
        );
      } else if (reason == "mailbox_is_disposable_address") {
        email_rules.push(
          i18n.t("common.disposable_one_time_email_address")
        );
      } else if (reason == "mailbox_is_role_address") {
        email_rules.push(
          i18n.t("common.this_email_address_is_a_forwarding_address")
        );
      } else if (reason.startsWith("No MX")) {
        email_rules.push(
          host + " " + i18n.t("common.does_not_serve_email")
        );
      }
    }

    if (check.risk == "high" || check.result == "undeliverable") {
      if (check.did_you_mean) {
        email_rules.push(
          i18n.t("invite_edit.did_you_mean", {alt_value: did_you_mean})
        );
      }
      return {
        valid: false,
        hard_no: true,
        email_rules
      }
    } else {
      return {
        valid: true,
        hard_no: false,
        email_rules
      }
    }
  }
}
