<template>
  <div>
    <div v-if="!subscription_types" style="margin-top: 200px">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-progress-circular size="200" width="20" color="primary" indeterminate></v-progress-circular>
        </v-layout>
      </v-container>
    </div>

    <div v-else-if="subscription_types && !selected_subscription_type_id">
      <h1
        class="mt-6 mb-6"
        style="font-family: industry !important; text-align: center; font-size: 4rem; letter-spacing: 1rem !important; padding-bottom: 50px"
      >
        TRAINING PACKAGES
      </h1>

      <div class="d-flex justify-space-around flex-wrap">
        <div v-for="subscription_type in subscription_types_array" :key="subscription_type.id">
          <v-card max-width="275" style="background-color: white; color: black; border-radius: 32px; margin-bottom: 100px">
            <template slot="progress">
              <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
            </template>

            <v-img v-if="subscription_type.image" height="250" :src="subscription_type.image"></v-img>

            <div class="mx-auto d-flex flex-row justify-space-around">
              <v-card-title
                class="justify-center px-6"
                :style="
                  'font-family: Lato; \
                  font-weight: bold; \
                  border-radius: 30px 30px 0 0;\
                  color: white;\
                  font-size: ' +
                  (subscription_type.title.length > 10 ? '1.6rem;' : '2rem;') +
                  'font-weight: bold;\
                  position: relative;\
                  top: ' +
                  (subscription_type.id == 'pro_package' ? '-65px;' : '-64px;') +
                  'margin-bottom: -64px;\
                  border-bottom: none;\
                  background-color: ' +
                  highlight_colour(subscription_type.id) +
                  ';' +
                  (subscription_type.id == 'pro_package' ? 'border: 1px solid white;' : '')
                "
              >
                {{ subscription_type.title }}
              </v-card-title>
            </div>

            <div class="mt-6">
              <h1 style="text-align: center">
                <span v-if="prices_by_subscription_type[subscription_type.id]">
                  <span v-for="price in prices_by_subscription_type[subscription_type.id]" :key="price.id" :value="price.id">
                    <div
                      v-html="
                        span_split_price(price_formatted(subscription_type.stripe_prices[price.id], subscription_price_amount(subscription_type, price.id)))
                      "
                    ></div>
                  </span>
                </span>
                <span v-else-if="subscription_type.id == 'custom'">
                  <span class="price-whole-amount">Contact</span>
                </span>
                <span v-else-if="subscription_type.id == 'free_trial'">
                  <span class="price-currency-symbol">$</span><span class="price-whole-amount">0</span>
                </span>
              </h1>

              <div class="mt-n3">
                <p style="text-align: center; font-family: 'Proxima Nova'; color: grey">
                  {{ under_price_text(subscription_type) }}
                </p>
              </div>
            </div>

            <h3 v-if="next_grid_schedule(subscription_type)" style="text-align: center">
              {{ next_grid_schedule(subscription_type).title }}
            </h3>

            <div v-if="next_grid_schedule(subscription_type)" class="subscription_type_short_desc mt-6">
              <p>
                <strong>{{ next_grid_schedule(subscription_type).description }}</strong>
              </p>
            </div>

            <div
              class="subscription_type_short_desc mt-6"
              style="font-size: 0.8rem"
              v-html="subscription_type.short_description ? subscription_type.short_description : subscription_type.description"
            ></div>

            <div class="pl-6 pr-6">
              <div v-if="subscription_type.first_time_addon" class="my-4 text-subtitle-1">
                {{ subscription_type.first_time_addon.title }}

                <v-card-text style="margin-top: -10px" v-html="subscription_type.first_time_addon.description"></v-card-text>
              </div>
            </div>

            <v-divider class="mx-4"></v-divider>

            <v-card-text v-if="prices_by_subscription_type[subscription_type.id] && prices_by_subscription_type[subscription_type.id].length > 1">
              <v-radio-group v-model="subscription_select_price_id[subscription_type.id]">
                <v-radio
                  v-for="price in prices_by_subscription_type[subscription_type.id]"
                  :key="price.id"
                  :value="price.id"
                  :label="
                    price.title + ': ' + price_formatted(subscription_type.stripe_prices[price.id], subscription_price_amount(subscription_type, price.id))
                  "
                ></v-radio>
              </v-radio-group>
            </v-card-text>

            <v-card-actions class="mx-auto d-flex flex-row justify-space-around" style="margin-top: -30px; position: relative; top: 30px">
              <v-btn
                :color="highlight_colour(subscription_type.id)"
                :style="'font-family: Lato; font-weight: bold;' + (subscription_type.id == 'pro_package' ? 'border: 1px solid white;' : '')"
                large
                rounded
                width="154"
                @click="select_subscription_type(subscription_type.id)"
              >
                {{ sign_up_button_text(subscription_type.id) }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else-if="selected_subscription_type_id" style="max-width: 800px; margin: auto">
      <!-- Payment Method Status -->
      <v-sheet v-if="complete_setup_intent_id && complete_setup_intent_client_secret" style="margin-top: 100px; padding: 50px">
        <div class="text-center mb-12">
          <h2>{{ $t("user_new_subscription.processing_payment") }}</h2>
        </div>

        <div v-if="subscription && subscription.stripe_subscription_status == 'incomplete'" class="text-center">
          <h2>{{ $t("common.error") }}</h2>

          <p>{{ $t("user_new_subscription.trouble_charging_payment") }}</p>

          <v-btn color="primary" class="mt-6" @click="try_again">{{ $t("common.try_again") }}</v-btn>
        </div>

        <div v-else-if="initiate_subscription_error" class="text-center">
          <h2>{{ $t("common.error") }}</h2>

          <p>{{ initiate_subscription_error }}</p>

          <v-btn color="primary" class="mt-6" @click="try_again">{{ $t("common.try_again") }}</v-btn>
        </div>

        <div v-else-if="!setup_intent || setup_intent.statys == 'processing' || setup_intent.status == 'succeeded'" class="text-center">
          <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
        </div>

        <!-- TODO: Handle these statusus properly -->
        <div v-else class="text-center">
          <h2>{{ setup_intent.status }}</h2>

          <p v-if="setup_intent.last_setup_error">
            {{ setup_intent.last_setup_error.message }}
          </p>

          <v-btn color="primary" class="mt-6" @click="try_again">{{ $t("common.try_again") }}</v-btn>
        </div>
      </v-sheet>
      <div v-else>
        <!-- Subscription Information -->
        <div style="padding-left: 50px; padding-right: 50px; margin-top: 50px">
          <v-btn
            v-if="
              (subscription_types_array.length > 1 || prices_by_subscription_type[selected_subscription_type_id].length > 1) &&
              !current_clinic.has_tag('user_subscription')
            "
            style="float: right"
            color="primary"
            small
            text
            @click="
              selected_subscription_type_id = '';
              stripe_mounted = false;
            "
            >{{ $t("user_new_subscription.choose_different_plan") }}</v-btn
          >
          <h2 style="margin-bottom: 10px">
            {{ selected_subscription_type.title }}
          </h2>

          <div v-if="selected_price && selected_price_amount" style="margin-bottom: 20px">
            <span style="font-weight: bold">
              {{ selected_price_formatted }}
              <span v-if="selected_price.type == 'recurring'">/ {{ $t("common." + selected_price.recurring.interval) }}</span>

              <span v-if="selected_subscription_type.single_purchase"> - One Time Purchase</span>
            </span>

            <span v-if="user_subscription_start_date">&nbsp;({{ $t("common.starting", {date: user_subscription_start_date}) }})</span>
          </div>

          <div v-html="selected_subscription_type.description"></div>
        </div>

        <!-- Addon Information -->
        <div v-if="selected_subscription_type && selected_subscription_type.first_time_addon" style="padding-left: 50px; padding-right: 50px">
          <h3 style="margin-bottom: 10px">
            {{ selected_subscription_type.first_time_addon.title }}
          </h3>

          <div v-if="addon_price && selected_addon_price_amount" style="margin-bottom: 20px">
            <span style="font-weight: bold">{{ addon_price_formatted }}</span>
            <span v-if="addon_price.type == 'one_time'">&nbsp;({{ $t("user_new_subscription.one_time_purchase") }})</span>
          </div>

          <div v-html="selected_subscription_type.first_time_addon.description"></div>
        </div>

        <div style="padding: 50px">
          <!-- Shipping Adress -->
          <template v-if="collect_shipping">
            <h2 style="margin-bottom: 30px">
              {{ $t("user_new_subscription.shipping_address") }}
            </h2>
            <div>
              <v-text-field v-model="shipping_line1" class="shipping_info_dark_input" outlined dense :label="$t('common.address')" type="text" />
              <v-text-field v-model="shipping_line2" class="shipping_info_dark_input" outlined dense type="text" />
              <v-layout justify-space-between>
                <v-flex md5>
                  <v-text-field v-model="shipping_city" class="shipping_info_dark_input" outlined dense :label="$t('common.city')" type="text" />
                </v-flex>
                <v-flex v-if="current_locale_country.district_name" md5>
                  <v-select
                    v-model="shipping_state"
                    outlined
                    dense
                    class="shipping_info_dark_input"
                    :items="shipping_district_items"
                    :label="shipping_district_name"
                  />
                </v-flex>
              </v-layout>

              <v-layout justify-space-between>
                <v-flex md5>
                  <v-text-field
                    v-model="shipping_postal_code"
                    outlined
                    dense
                    class="shipping_info_dark_input"
                    :label="current_shipping_country.postal_name"
                    type="text"
                  />
                </v-flex>

                <v-flex md5>
                  <v-select v-model="shipping_country" outlined dense class="shipping_info_dark_input" :items="supported_countries" :label="$t('common.country')" />
                </v-flex>
              </v-layout>
            </div>
          </template>

          <div>
            <h2 style="margin-bottom: 20px; margin-top: 30px">
              {{ $t("user_new_subscription.payment_information") }}
            </h2>
            <v-layout justify-space-around style="min-height: 150px">
              <div id="stripe-payment-element" style="margin-top: 10px; width: 700px"></div>
            </v-layout>
          </div>

          <div
            v-if="
              selected_subscription_type &&
              (selected_subscription_type.first_time_addon || !user_subscription_start_date) &&
              (selected_price_amount || selected_addon_price_amount)
            "
            :style="summary_style"
          >
            <h3 style="padding-top: 20px">
              {{ $t("user_new_subscription.due_now") }}
            </h3>
            <table style="width: 100%; border-collapse: collapse">
              <tr v-if="!user_subscription_start_date">
                <td style="padding: 10px">
                  {{ selected_subscription_type.title }}
                  <span v-if="selected_price && selected_price.recurring && selected_price.recurring.interval == 'month'"
                    >({{ $t("user_new_subscription.first_month") }})</span
                  >
                  <span v-if="selected_price && selected_price.recurring && selected_price.recurring.interval == 'year'"
                    >({{ $t("user_new_subscription.one_year") }})</span
                  >
                </td>
                <td style="text-align: right; padding: 10px">
                  {{ selected_price_formatted }}
                </td>
              </tr>
              <tr v-if="selected_subscription_type.first_time_addon">
                <td style="padding: 10px">
                  {{ selected_subscription_type.first_time_addon.title }}
                </td>
                <td style="text-align: right; padding: 10px">
                  {{ addon_price_formatted }}
                </td>
              </tr>
              <tr v-if="customer_balance_amount">
                <td style="padding: 10px">Credit</td>
                <td style="text-align: right; padding: 10px">
                  {{ customer_balance_amount_formatted }}
                </td>
              </tr>
              <tr v-if="total_due_formatted" style="font-weight: bold">
                <td style="padding: 10px"></td>
                <td style="text-align: right; padding: 10px">
                  {{ $t("user_new_subscription.total") }}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{ total_due_formatted }}
                </td>
              </tr>
            </table>
          </div>

          <v-layout style="margin-top: 30px">
            <v-flex v-if="allow_user_coupon" md5>
              <v-text-field
                v-model="user_promo_code"
                class="shipping_info_dark_input"
                outlined
                dense
                :label="$t('user_new_subscription.coupon_code')"
                type="text"
                :append-icon="user_promo_code && applied_promo_code == user_promo_code ? 'mdi-check' : ''"
              />
            </v-flex>
            <v-flex v-if="allow_user_coupon" md1 style="margin-left: 15px; margin-top: 5px">
              <v-btn text small :loading="loading_promo_code" @click="apply_user_promo_code">{{ $t("user_new_subscription.apply_code") }}</v-btn>
            </v-flex>
            <v-flex md12>
              <v-btn color="primary" style="float: right" :disabled="in_flight" :loading="in_flight" @click="submit">{{
                selected_subscription_type.single_purchase ? "Sign up" : $t("user_new_subscription.subscribe")
              }}</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-if="error_message" style="margin-top: 50px; margin-right: 300px">
        <v-alert type="error">{{ error_message }}</v-alert>
      </div>
    </div>

    <!-- Popup for contact us -->
    <v-dialog v-model="contact_us_dialog" max-width="600">
      <v-card>
        <v-card-title class="headline">
          {{ $t("common.contact_us") }}
        </v-card-title>
        <v-card-text>
          <p>Contact us for custom pricing and programming for Coaches, Clubs, Facilities, Schools, Universities, Pro Teams, and more.</p>
          <h3 class="text-center mt-12"><a href="mailto:info@visionxsports.com">info@visionxsports.com</a></h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="contact_us_dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js/pure";
import all_countries from "../../../data/all_countries_list.json";
import locales from "../../../data/locales.yml";
import { mapState } from "vuex";

export default {
  components: {},
  props: {
    current_clinic: {
      type: Object,
      required: true,
    },
  },
  data() {
    let url_search = new URLSearchParams(window.location.search);

    return {
      complete_setup_intent_id: url_search.get("setup_intent") || "",
      complete_setup_intent_client_secret: url_search.get("setup_intent_client_secret") || "",
      complete_setup_intent_once: false,
      subscription: null,
      setup_intent: null,
      stripe_loaded: false,
      stripe_mounted: false,
      stripe: null,
      stripe_elements: null,
      stripe_card: null,
      stripe_customer: null,
      subscription_types: null,
      stripe_coupon: null,
      allow_user_coupon: true,
      loading_promo_code: false,
      user_promo_code: url_search.get("user_promo_code") || "",
      applied_promo_code: "",
      selected_subscription_type_id: url_search.get("selected_subscription_type_id") || "",
      selected_price_id: url_search.get("selected_price_id") || "",
      all_countries: all_countries,
      error_message: "",
      in_flight: false,
      current_locale_country: locales[this.current_clinic.billing_country],
      billing_country: this.current_clinic.billing_country,
      shipping_line1: url_search.get("shipping_line1") || "",
      shipping_line2: url_search.get("shipping_line2") || "",
      shipping_city: url_search.get("shipping_city") || "",
      shipping_state: url_search.get("shipping_state") || "",
      shipping_postal_code: url_search.get("shipping_postal_code") || "",
      shipping_country: url_search.get("shipping_country") || this.current_clinic.billing_country,
      setup_intent_secret: "",
      subscription_select_price_id: {},
      payment_intent: url_search.get("payment_intent") || "",
      payment_intent_client_secret: url_search.get("payment_intent_client_secret") || "",
      payment_intent_subscription_id: url_search.get("payment_intent_subscription_id") || "",
      stripe_appearance: {
        theme: this.$vuetify.theme.dark ? "night" : "stripe",
      },
      initiate_subscription_error: "",
      contact_us_dialog: false,
    };
  },
  computed: {
    ...mapState(["current_user", "stripe_public_key", "current_invite", "user_regimens", "free_trial_grid_schedules", "grid_schedules", "user_subscriptions"]),
    supported_countries() {
      // Canada and USA from "all_countires" filtered
      return this.all_countries.filter((country) => country.value == "CA" || country.value == "US");
    },
    current_shipping_country() {
      return locales[this.shipping_country];
    },
    customer_balance_amount() {
      if (!this.stripe_customer || !this.stripe_customer.balance || this.stripe_customer.balance > 0) {
        return 0;
      }

      return this.stripe_customer.balance / 100;
    },
    customer_balance_amount_formatted() {
      if (!this.customer_balance_amount) {
        return "";
      }

      var formatter = new Intl.NumberFormat(this.$i18n.lang(), {
        style: "currency",
        currency: this.stripe_customer.currency,
      });

      let price = formatter.format(this.customer_balance_amount).toString();

      // Remove leading ISO codes and trailing .00
      if (/^[A-Z]{2}/.test(price)) {
        price = price.substring(2);
      }
      if (price.endsWith(".00")) {
        price = price.substring(0, price.length - 3);
      }

      if (this.stripe_customer.currency != "usd" && price.startsWith("$")) {
        price = price + " " + stripe_price.currency.toUpperCase();
      }

      return price;
    },
    user_regimen() {
      for (let user_regimen of this.user_regimens) {
        if (user_regimen.clinic_id == this.current_clinic.id) {
          return user_regimen;
        }
      }
      return null;
    },
    stripe_live_mode() {
      return this.stripe_public_key.startsWith("pk_live");
    },
    collect_shipping() {
      return (
        this.selected_subscription_type &&
        (this.selected_subscription_type.shipping ||
          (this.selected_subscription_type.first_time_addon && this.selected_subscription_type.first_time_addon.shipping))
      );
    },
    shipping_district_items() {
      let items = [];
      let country = locales[this.shipping_country];
      if (country) {
        if (country.districts) {
          let districts = country.districts;
          for (var code in districts) {
            items.push({ value: code, text: districts[code].name });
          }
        }
      }

      items.sort((a, b) => (a.value > b.value ? 1 : -1));

      return items;
    },
    shipping_district_name() {
      let country = locales[this.shipping_country];
      if (country) {
        return country.district_name;
      } else {
        return "";
      }
    },
    selected_subscription_type() {
      if (!this.selected_subscription_type_id || !this.subscription_types) {
        if (this.subscription_types_array.length == 1) {
          return this.subscription_types_array[0];
        } else {
          return null;
        }
      } else {
        return this.subscription_types[this.selected_subscription_type_id] || null;
      }
    },
    selected_price() {
      if (!this.selected_subscription_type) {
        return null;
      }
      if (this.selected_subscription_type.stripe_prices[this.selected_price_id]) {
        return this.selected_subscription_type.stripe_prices[this.selected_price_id];
      } else {
        return null;
      }
    },
    addon_price() {
      if (!this.selected_subscription_type || !this.selected_subscription_type.first_time_addon) {
        return null;
      }
      let price_id;
      if (this.stripe_live_mode) {
        price_id = this.selected_subscription_type.first_time_addon.price_live;
      } else {
        price_id = this.selected_subscription_type.first_time_addon.price_test;
      }
      return this.selected_subscription_type.stripe_prices[price_id];
    },
    selected_price_amount() {
      if (!this.selected_price || !this.selected_subscription_type) {
        return 0;
      }

      return this.subscription_price_amount(this.selected_subscription_type, this.selected_price.id);
    },
    selected_addon_price_amount() {
      if (!this.addon_price || !this.selected_subscription_type || !this.selected_subscription_type.first_time_addon) {
        return 0;
      }

      return this.subscription_addon_price_amount(this.selected_subscription_type, this.addon_price);
    },
    selected_price_formatted() {
      if (!this.selected_price) {
        return "";
      }

      return this.price_formatted(this.selected_price, this.selected_price_amount);
    },
    addon_price_formatted() {
      if (!this.addon_price) {
        return "";
      }
      return this.price_formatted(this.addon_price, this.selected_addon_price_amount);
    },
    total_due_formatted() {
      let total = 0;
      if (this.selected_price && !this.user_subscription_start_date) {
        total += this.selected_price_amount;
      }
      if (this.addon_price) {
        total += this.selected_addon_price_amount;
      }

      if (total == 0) {
        return "";
      }

      if (this.customer_balance_amount) {
        total = total + this.customer_balance_amount;
      }

      return this.price_formatted(this.selected_price, total);
    },
    user_subscription_start_date() {
      if (this.current_clinic.has_tag("user_subscription_start_date")) {
        let start_date = Date.parse(this.current_clinic.tag_value("user_subscription_start_date"));

        // If it's in the past, ignore it
        const today = new Date();
        if (start_date < today) {
          return "";
        }

        var options = {
          month: "long",
          day: "numeric",
          year: "numeric",
        };
        let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
        return formatter.format(start_date);
      } else {
        return "";
      }
    },
    subscription_types_array() {
      let sub_types_array = [];

      if (
        (!this.user_regimen || !this.user_regimen.grid_schedule_id || !this.free_trial_grid_schedules.includes(this.user_regimen.grid_schedule_id)) &&
        this.current_clinic &&
        this.current_clinic.has_tag("user_subscription_free_trial")
      ) {
        sub_types_array.push({
          id: "free_trial",
          title: "Free Trial",
          short_description:
            "<p>Includes the Pro+ Package with Tech-Based and Physical Drills. After your trial ends, you have the option to sign up for Pro or Pro+.<p> <ul><li>Tech-Based Drill Work</li><li>Physical Performance Drills</li></ul>",
        });
      }

      Object.values(this.subscription_types)
        .filter((sub_type) => sub_type.selectable)
        .forEach((sub_type) => {
          sub_types_array.push(sub_type);
        });

      sub_types_array.push({
        id: "custom",
        title: "Custom",
        short_description:
          "<p>Practice-ready programming and/or individual training options for Coaches, Clubs, Facilities, Schools, Universities, Pro Teams, and more.<p> <ul><li>Custom Programming Options</li><li>Custom Pricing Options</li></ul>",
      });

      return sub_types_array;
    },
    prices_by_subscription_type() {
      let sub_types = {};
      for (var sub_type_id in this.subscription_types) {
        let sub_type = this.subscription_types[sub_type_id];
        sub_types[sub_type_id] = Object.values(sub_type.prices);
      }
      return sub_types;
    },
    summary_style() {
      let style =
        "\
        padding-left: 20px;\
        padding-right: 20px;\
        margin-top: 20px;\
        border-radius: 4px;\
        padding-bottom: 10px;\
      ";

      // Check if the user has a dark theme
      if (this.$vuetify.theme.dark) {
        style += "background-color: rgb(48, 49, 61); border: 1px solid DimGray;";
      } else {
        style += "background-color: rgb(255, 255, 255); border: 1px solid LightGray;";
      }

      return style;
    },
    setup_return_url() {
      let return_url =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?selected_subscription_type_id=" +
        encodeURIComponent(this.selected_subscription_type_id) +
        "&selected_price_id=" +
        encodeURIComponent(this.selected_price_id) +
        "&shipping_line1=" +
        encodeURIComponent(this.shipping_line1) +
        "&shipping_line2=" +
        encodeURIComponent(this.shipping_line2) +
        "&shipping_city=" +
        encodeURIComponent(this.shipping_city) +
        "&shipping_state=" +
        encodeURIComponent(this.shipping_state) +
        "&shipping_postal_code=" +
        encodeURIComponent(this.shipping_postal_code) +
        "&shipping_country=" +
        encodeURIComponent(this.shipping_country) +
        "&user_promo_code=" +
        encodeURIComponent(this.user_promo_code);

      return return_url;
    },
    payment_return_url() {
      let return_url = window.location.protocol + "//" + window.location.host + window.location.pathname;

      let subscription_id = this.payment_intent_subscription_id || (this.subscription && this.subscription.id);

      if (subscription_id) {
        return_url += "?payment_intent_subscription_id=" + subscription_id;
      }

      return return_url;
    },
  },
  watch: {
    local_value(newVal, oldVal) {
      if (newVal.billing_country != oldVal.billing_country) {
        this.billing_district = null;
      }
    },
    shipping_country(newVal, oldVal) {
      if (newVal != oldVal) {
        this.shipping_state = null;
      }
    },
  },
  mounted() {
    // If we have a payment_intent and payment_intent_client_secret, it means we just completed a payment_intent confirmation
    if (this.payment_intent && this.payment_intent_client_secret && this.payment_intent_subscription_id) {
      this.load_stripe();
      this.confirm_incomplete_invoice();
      return;
    }

    if (!this.complete_setup_intent_id && !this.complete_setup_intent_client_secret) {
      this.load_setup_intent();
    }
    this.load_stripe();
    this.load_subscription_types();
    this.load_clinic_coupon();
    this.apply_user_promo_code();
    this.load_stripe_customer();

    // Load grid schedules
    this.$store.dispatch("loadGrids");
  },
  methods: {
    next_grid_schedule(sub_type) {
      let next_grid_schedule_id = null;

      if (sub_type.default_grid_schedule) {
        next_grid_schedule_id = sub_type.default_grid_schedule;
      } else if (sub_type.grid_schedule_series) {
        if (!this.user_regimen || !this.user_regimen.grid_schedule) {
          return null;
        }

        let current_grid_schedule_id = this.user_regimen.grid_schedule.id;
        // Find the next grid schedule in the series, that comes after the current grid schedule
        // If the current grid schedule is the last in the series, then return the first grid schedule in the series
        let found_current = false;
        for (let grid_schedule_id of sub_type.grid_schedule_series) {
          if (found_current) {
            next_grid_schedule_id = grid_schedule_id;
            break;
          }
          if (grid_schedule_id == current_grid_schedule_id) {
            found_current = true;
          }
        }
        if (!next_grid_schedule_id) {
          // If the current grid schedule the last in the series, then return null, as there is no next grid schedule
          if (found_current) {
            return null;
          }
          next_grid_schedule_id = sub_type.grid_schedule_series[0];
        }
      }

      if (!next_grid_schedule_id) {
        return null;
      }

      for (let grid_sched of this.grid_schedules) {
        if (grid_sched.id == next_grid_schedule_id) {
          return grid_sched;
        }
      }

      return null;
    },
    under_price_text(sub_type) {
      if (sub_type.id == "free_trial") {
        return "7-day trial";
      } else if (sub_type.id == "custom") {
        return "for custom programs and pricing";
      } else if (sub_type.single_purchase) {
        return "";
      } else {
        return "per month";
      }
    },
    sign_up_button_text(id) {
      if (id == "free_trial") {
        return "Start Trial";
      } else if (id == "custom") {
        return "Contact Us";
      } else {
        return this.$t("common.sign_up");
      }
    },
    highlight_colour(id) {
      if (id == "pro_package") {
        return "#000000";
      } else if (id == "pro_plus_package") {
        return "#ec1f28";
      } else if (id == "the_pro_plus") {
        return "#ec1f28";
      } else if (id == "vision_assessment") {
        return "#000000";
      } else {
        return "#706e6e";
      }
    },
    load_clinic_coupon() {
      if (this.current_clinic.has_tag("user_subscription_coupon")) {
        this.allow_user_coupon = false;
        let coupon_id = this.current_clinic.tag_value("user_subscription_coupon");
        axios
          .get("/api/stripe/coupon/" + coupon_id)
          .then((response) => {
            let resp = response.data;
            if (resp.status == "success") {
              this.stripe_coupon = resp.data;
            } else {
              messageBus.$emit("api-error", resp);
            }
          })
          .catch((error) => {
            messageBus.$emit("api-error", error);
          });
      }
    },
    apply_user_promo_code() {
      this.user_promo_code = this.user_promo_code.trim();
      if (!this.user_promo_code) {
        return;
      }

      this.loading_promo_code = true;
      axios
        .get("/api/stripe/coupon/" + this.user_promo_code + "?promo_code=true")
        .then((response) => {
          this.loading_promo_code = false;
          let resp = response.data;
          if (resp.status == "success") {
            this.stripe_coupon = resp.data;
            this.applied_promo_code = this.user_promo_code;
            messageBus.$emit("success", this.$t("user_subscription.promo_code_success"));
          } else {
            messageBus.$emit("error", this.$t("user_subscription.invalid_promo_code"));
          }
        })
        .catch((error) => {
          messageBus.$emit("api-error", error);
        });
    },
    load_stripe_customer() {
      if (!this.current_user.stripe_customer_id) {
        return;
      }
      axios
        .get("/api/user/" + this.current_user.id + "/billing/customer")
        .then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            this.stripe_customer = resp.data;
            // If there's no shipping address, but there is in sthe stripe_customer, then load it from the stripe customer
            if (!this.shipping_line1 && this.stripe_customer.shipping && this.stripe_customer.shipping.address) {
              this.shipping_line1 = this.stripe_customer.shipping.address.line1;
              this.shipping_line2 = this.stripe_customer.shipping.address.line2;
              this.shipping_city = this.stripe_customer.shipping.address.city;
              this.shipping_state = this.stripe_customer.shipping.address.state;
              this.shipping_postal_code = this.stripe_customer.shipping.address.postal_code;
              this.shipping_country = this.stripe_customer.shipping.address.country;
            }
          } else {
            messageBus.$emit("api-error", resp);
          }
        })
        .catch((error) => {
          messageBus.$emit("api-error", error);
        });
    },
    load_subscription_types() {
      axios
        .get("/api/subscription_types?include_stripe&strategy=user&country=" + this.current_clinic.billing_country)
        .then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            this.subscription_types = resp.data;

            if (Object.keys(this.subscription_types).length == 1) {
              this.selected_subscription_type_id = Object.keys(this.subscription_types)[0];
            } else if (this.current_clinic.has_tag("user_subscription")) {
              this.selected_subscription_type_id = this.current_clinic.tag_value("user_subscription");
            }

            if (this.selected_subscription_type_id && !this.selected_price_id) {
              let prices = Object.values(this.subscription_types[this.selected_subscription_type_id].prices);
              this.selected_price_id = prices[0].id;
            }

            for (let sub_type of Object.values(this.subscription_types)) {
              for (let price of Object.values(sub_type.prices)) {
                this.subscription_select_price_id[sub_type.id] = price.id;
                break;
              }
            }

            this.maybe_mount_stripe();
          } else {
            messageBus.$emit("api-error", resp);
          }
        })
        .catch((error) => {
          messageBus.$emit("api-error", error);
        });
    },
    mount_elements() {
      this.stripe_elements = this.stripe.elements({
        clientSecret: this.setup_intent_secret,
        appearance: this.stripe_appearance,
      });
      this.payment_element = this.stripe_elements.create("payment");

      Vue.nextTick(() => {
        this.payment_element.mount("#stripe-payment-element");
        this.stripe_mounted = true;
      });
    },
    load_stripe() {
      let key = this.stripe_public_key;
      loadStripe(key, { locale: this.$i18n.lang() }).then((stripe) => {
        this.stripe = stripe;
        this.stripe_loaded = true;
        this.maybe_mount_stripe();
      });
    },
    maybe_mount_stripe() {
      if (this.complete_setup_intent_id && this.complete_setup_intent_client_secret && this.stripe_loaded) {
        if (this.complete_setup_intent_once) {
          return;
        } else {
          this.complete_setup_intent_once = true;
          this.complete_setup_intent();
        }
      } else if (this.stripe_loaded && !this.stripe_mounted && this.setup_intent_secret && this.selected_subscription_type_id) {
        this.mount_elements();
      }
    },
    complete_setup_intent() {
      // Retrieve the SetupIntent
      this.stripe.retrieveSetupIntent(this.complete_setup_intent_client_secret).then(({ setupIntent }) => {
        this.setup_intent = setupIntent;

        if (this.setup_intent.status == "processing") {
          window.setTimeout(() => {
            this.complete_setup_intent();
          }, 1000);
        }

        if (this.setup_intent.status == "succeeded") {
          this.initiate_subscription();
        }

        // TODO: There a BUNCH more status's that need handling
        // requires_payment_method, requires_confirmation, requires_action, processing, canceled, or succeeded
      });
    },
    reset() {
      this.stripe_loaded = false;
      this.stripe_mounted = false;
    },
    check_address() {
      if (!this.collect_shipping) {
        return true;
      }

      if (!this.shipping_line1) {
        this.error_message = this.$t("user_new_subscription.please_provide_a_shipping_street_address");
        return false;
      }
      if (!this.shipping_city) {
        this.error_message = this.$t("user_new_subscription.please_provide_a_shipping_city");
        return false;
      }
      if (!this.shipping_state) {
        if (this.current_locale_country.district_name) {
          let district_name = this.current_locale_country.district_name;
          this.error_message = this.$t("clinic_billing.please_select_a") + " " + district_name;
          return false;
        }
      }
      if (!this.shipping_postal_code) {
        let country = locale[this.shipping_country]
        this.error_message = this.$t("user_new_subscription.please_provide_a_shipping_postal", {postal_name: country.postal_name});
        return false;
      }
      return true;
    },
    load_setup_intent() {
      axios.post("/api/user/" + this.current_user.id + "/billing/setup_intent").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.setup_intent_secret = resp.data;
          this.maybe_mount_stripe();
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    submit() {
      if (!this.check_address()) {
        return;
      }
      this.in_flight = true;

      this.stripe
        .confirmSetup({
          elements: this.stripe_elements,
          confirmParams: { return_url: this.setup_return_url },
        })
        .then(({ error }) => {
          this.in_flight = false;
          if (error) {
            this.error_message = error.message;
          }
        });
    },
    try_again() {
      let location = window.location.href;
      location = removeURLParameter(location, "setup_intent");
      location = removeURLParameter(location, "setup_intent_client_secret");
      window.location.href = location;
    },
    initiate_subscription() {
      let sub_initiation = {
        subscription_type: this.selected_subscription_type_id,
        user_id: this.current_user.id,
        clinic_id: this.current_clinic.id,
        invite_id: this.current_invite.id,
        payment_method: this.setup_intent.payment_method,
        user_promo_code: this.user_promo_code,
        price: this.selected_price_id,
        shipping_line1: this.shipping_line1 ? this.shipping_line1 : null,
        shipping_line2: this.shipping_line2 ? this.shipping_line2 : null,
        shipping_city: this.shipping_city ? this.shipping_city : null,
        shipping_state: this.shipping_state ? this.shipping_state : null,
        shipping_postal_code: this.shipping_postal_code ? this.shipping_postal_code : null,
        shipping_country: this.shipping_country ? this.shipping_country : null,
      };

      axios.post("/api/subscription/initiate/user", sub_initiation).then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.subscription = resp.data;

          // Check if it's a normal stripe-subscription that is active or trialing
          if (this.subscription.stripe_subscription_status == "active" || this.subscription.stripe_subscription_status == "trialing") {
            window.location =
              window.location.protocol + "//" + window.location.host + "/app/clinic/" + this.current_clinic.id + "?user_subscription_success=true";
          }

          // Check if it's a non-stripe subscription
          if (!this.subscription.stripe_subscription_id && !this.subscription.stripe_subscription_status && this.subscription.end_date) {
            // Check this.subscription.end_date to see if it's a one-time purchase and if it's in the future
            let end_date = new Date(this.subscription.end_date);
            let today = new Date();
            if (end_date > today) {
              window.location =
                window.location.protocol + "//" + window.location.host + "/app/clinic/" + this.current_clinic.id + "?user_subscription_success=true";
            }
          }

          // If it's incomplete, then see if we need to confirm the payment, or if it's actually success
          if (this.subscription.stripe_subscription_status == "incomplete") {
            this.confirm_incomplete_invoice();
          }
        } else {
          this.initiate_subscription_error = resp.error_message;
        }
      });
    },

    confirm_incomplete_invoice() {
      this.initiate_subscription_error = "";
      let subscription_id = this.payment_intent_subscription_id || (this.subscription && this.subscription.id);

      axios.post("/api/subscription/" + subscription_id + "/complete_invoice").then((complete_invoice_response) => {
        let complete_invoice_resp = complete_invoice_response.data;
        if (complete_invoice_resp.status == "success") {
          if (complete_invoice_resp.data.step == "success") {
            window.location = window.location.protocol + "//" + window.location.host + window.location.pathname + "?user_subscription_success=true";
          } else if (complete_invoice_resp.data.step == "confirm_payment_intent") {
            this.stripe
              .confirmPayment({
                clientSecret: complete_invoice_resp.data.payment_intent_client_secret,
                confirmParams: {
                  return_url: this.payment_return_url,
                },
              })
              .then((confirm_payment_res) => {
                if (confirm_payment_res.error) {
                  this.initiate_subscription_error = confirm_payment_res.error.message;
                }
              });
          } else {
            this.initiate_subscription_error = this.$t("user_new_subscription.trouble_charging_payment");
          }
        } else {
          this.initiate_subscription_error = resp.error_message;
        }
      });
    },

    span_split_price(price) {
      const currencySymbols = [
        "$",
        "€",
        "£",
        "¥",
        "₹",
        "₽",
        "₩",
        "₪",
        "฿",
        "₫",
        "₱",
        "₲",
        "₴",
        "₵",
        "₸",
        "₼",
        "֏",
        "៛",
        "₡",
        "₮",
        "₦",
        "₢",
        "₥",
        "₤",
        "₣",
        "₧",
        "₯",
        "₠",
        "₰",
        "₷",
      ];
      let currencySymbol = "";
      let numericPart = price;

      for (let symbol of currencySymbols) {
        if (price.startsWith(symbol)) {
          currencySymbol = symbol;
          numericPart = price.substring(symbol.length);
          break;
        }
      }

      const [whole, cents] = numericPart.split(".");
      return `${currencySymbol ? `<span class="price-currency-symbol">${currencySymbol}</span>` : ""}<span class="price-whole-amount">${whole}</span>${
        cents ? `<span class="price-cents">.${cents}</span>` : ""
      }`;
    },
    subscription_price_amount(subscription_type, price_id) {
      let price = subscription_type.stripe_prices[price_id];

      if (!price) {
        return Error("Price not found");
      }

      let price_amount = price.unit_amount;
      if (this.stripe_coupon) {
        if (
          !this.stripe_coupon.applies_to ||
          (this.stripe_live_mode && this.stripe_coupon.applies_to.products.includes(subscription_type.product_live)) ||
          (!this.stripe_live_mode && this.stripe_coupon.applies_to.products.includes(subscription_type.product_test))
        ) {
          if (this.stripe_coupon.percent_off) {
            price_amount = price_amount * (1 - this.stripe_coupon.percent_off / 100);
          }
          if (this.stripe_coupon.amount_off) {
            price_amount = price_amount - this.stripe_coupon.amount_off;
          }
        }
      }

      return price_amount / 100;
    },
    subscription_addon_price_amount(subscription_type, addon_price) {
      if (!subscription_type.first_time_addon) {
        return 0;
      }

      let price_amount = addon_price.unit_amount;

      if (this.stripe_coupon) {
        if (
          !this.stripe_coupon.applies_to ||
          (this.stripe_live_mode && this.stripe_coupon.applies_to.products.includes(subscription_type.first_time_addon.product_live)) ||
          (!this.stripe_live_mode && this.stripe_coupon.applies_to.products.includes(subscription_type.first_time_addon.product_test))
        ) {
          if (this.stripe_coupon.percent_off) {
            price_amount = price_amount * (1 - this.stripe_coupon.percent_off / 100);
          }
          if (this.stripe_coupon.amount_off) {
            price_amount = price_amount - this.stripe_coupon.amount_off;
          }
        }
      }

      return price_amount / 100;
    },
    price_formatted(stripe_price, amount) {
      if (amount instanceof Error) {
        return "ERROR";
      }

      var formatter = new Intl.NumberFormat(this.$i18n.lang(), {
        style: "currency",
        currency: stripe_price.currency,
      });

      let price = formatter.format(amount).toString();

      // Remove leading ISO codes and trailing .00
      if (/^[A-Z]{2}/.test(price)) {
        price = price.substring(2);
      }
      if (price.endsWith(".00")) {
        price = price.substring(0, price.length - 3);
      }

      if (stripe_price.currency != "usd" && price.startsWith("$")) {
        price = price + " " + stripe_price.currency.toUpperCase();
      }

      return price;
    },
    select_subscription_type(subscription_type_id) {
      if (subscription_type_id == "free_trial") {
        this.start_free_trial();
        return;
      }
      if (subscription_type_id == "custom") {
        this.contact_us_dialog = true;
        return;
      }

      this.selected_subscription_type_id = subscription_type_id;
      this.selected_price_id = this.subscription_select_price_id[subscription_type_id];
      Vue.nextTick(() => {
        this.maybe_mount_stripe();
      });
    },
    start_free_trial() {
      this.in_flight = true;
      axios
        .post("/api/start_free_trial", {
          free_trial_grid_schedule: "c39aecfe-9587-48d9-8f82-bfdfb0bed068",
          invite_id: this.current_invite.id,
        })
        .then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            location.reload();
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
    },
  },
};

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
}
</script>

<style>
.theme--dark .shipping_info_dark_input .v-input__slot fieldset,
.theme--dark .shipping_info_dark_input .v-select_slot fieldset {
  background-color: rgb(48, 49, 61);
}

.subscription_type_short_desc p {
  font-size: 0.7rem;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Roboto Condensed", "Roboto", sans-serif;
}

.subscription_type_short_desc ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ffffff;
  overflow: hidden;
}

.subscription_type_short_desc li {
  padding: 10px 50px;
  background-color: #fff;
  position: relative;
  font-family: "Proxima Nova";
}

.subscription_type_short_desc li:before {
  font-family: "Material Design Icons"; /* Set the font to Material Design Icons */
  content: "\FE6E"; /* This is an example code; you need the specific Unicode for the mdi-check icon */
  color: #706e6e;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
}

/* Use :nth-child(odd/even) to style alternating list items */
.subscription_type_short_desc li:nth-child(odd) {
  background-color: #ededed;
}

.subscription_type_short_desc li:nth-child(even) {
  background-color: #f9f9f9;
}

.price-currency-symbol {
  font-size: 0.5em;
  position: relative;
  top: -1.5em;
  font-family: "Lato";
}

.price-whole-amount {
  font-size: 1.5em;
  font-family: "Lato";
  font-weight: 800;
}

.price-cents {
  font-size: 0.8em;
  position: relative;
  top: -0.8em;
  font-family: "Lato";
}
</style>
