var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{on:{"click":_vm.focus}},[_c('v-sheet',{staticClass:"calibration_sheet",staticStyle:{"padding":"20px","text-align":"center"},attrs:{"elevation":"2"}},[_c('p',{staticStyle:{"font-size":"1.1rem"},domProps:{"innerHTML":_vm._s(_vm.$t('calibration_colour.calibration_colour_message', {
        side_from: this.$t(`common.${_vm.side}`),
        side_to: _vm.opposite(_vm.side),
        opposite_colour: this.$t(`common.${_vm.opposite_colour}`),
        opposite_colour_css: _vm.opposite_colour,
        colour: this.$t(`common.${_vm.colour}`)
      }))}}),_c('div',{staticClass:"box"},[_c('span',{staticClass:"dot",style:({ 'background-color': _vm.dotColour })})]),_c('v-slider',{ref:"CalibrationColourSlider",staticClass:"calibration_colour_slider",attrs:{"id":_vm.slider_id,"min":20,"max":100},on:{"change":function($event){return _vm.$emit('change', _vm.value)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{on:{"click":_vm.decrement}},[_vm._v("mdi-minus")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{on:{"click":_vm.increment}},[_vm._v("mdi-plus")])]},proxy:true}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }