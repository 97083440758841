<template>
  <div>
    <v-alert v-if="bad_invite_code_error && invite_token && !invite" type="error" text style="font-size: small">
      <div style="text-align: center" v-html="bad_invite_code_error" />
    </v-alert>
    <v-flex v-if="!invite_token && !invite" style="max-width: 350px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t("accept_invite.accept_invitation") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-alert v-if="bad_invite_code_error && !invite_token" type="error" text style="font-size: small">
            <div style="text-align: center" v-html="bad_invite_code_error" />
          </v-alert>
          <div>{{ $t("accept_invite.to_start_type_code") }}</div>
          <v-container>
            <v-layout justify-space-around>
              <v-flex xs4>
                <v-text-field
                  ref="token_input_first"
                  v-model="token_first"
                  class="accept-invite-token-input"
                  autofocus
                  outlined
                  type="text"
                  maxlength="4"
                  size="4"
                  @keyup="token_first_input_keyup"
                  @paste="token_paste"
                />
              </v-flex>
              <v-flex xs1>
                <v-icon style="text-align: middle; position: relative; top: 14px">fas fa-minus</v-icon>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  ref="token_input_second"
                  v-model="token_second"
                  class="accept-invite-token-input"
                  outlined
                  type="text"
                  maxlength="4"
                  size="4"
                  @keyup="token_second_input_keyup"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </v-flex>
    <div v-else-if="show_scroll_tos" class="accept_invite_tos_container">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t("user_signup.terms_of_service") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-textarea id="accept-invite-tos-textarea" ref="tos_textarea" :rows="20" :value="tos_text" no-resize readonly />
        </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row justify="space-between" align="center">
              <p class="mx-2">{{ $t("user_sign_up.scroll_to_bottom") }}</p>

              <v-spacer />

              <v-btn class="ml-1" :disabled="scroll_tos_at_bottom == false" @click="decline_tos()">{{ $t("user_sign_up.decline") }}</v-btn>

              <v-btn class="ml-2" color="primary" :disabled="scroll_tos_at_bottom == false || in_flight" @click="accept_tos()">{{
                $t("user_sign_up.accept")
              }}</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </div>
    <v-container v-else-if="invite" id="opticalgym_accept_invite_modal">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t("accept_invite.accept_invitation") }} - {{ invite.email }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text v-if="invite && invite.user_id">
          <v-alert v-if="bad_invite_code_error" type="error" text style="font-size: small">
            <div style="text-align: center" v-html="bad_invite_code_error" />
          </v-alert>
          <Login v-if="show_login" embed :default_email="invite.email" :show_invite_link="false" />
        </v-card-text>
        <v-card-text v-if="invite && !invite.user_id">
          <v-alert v-if="bad_invite_code_error" type="error" text style="font-size: small">
            <div style="text-align: center" v-html="bad_invite_code_error" />
          </v-alert>
          <div v-if="bad_invite_code_error && bad_invite_show_logout && current_user">
            <v-sheet style="padding: 50px">
              <p>
                {{ $t("accept_invite.currently_logged_in_as", { name_given: current_user.name_given, name_family: current_user.name_family }) }}
                ({{ current_user.email }})
              </p>

              <p>{{ $t("accept_invite.this_invite_is_for", { name_given: invite.name, name_family: invite.name_family }) }} ({{ invite.email }})</p>

              <v-layout justify-space-between>
                <v-btn :disabled="logout_in_progress" @click="logout">{{ $t("accept_invite.log_out_and_accept_invite") }}</v-btn>
                <v-btn href="/app">{{ $t("accept_invite.continue_as", { name_given: current_user.name_given, name_family: current_user.name_family }) }}</v-btn>
              </v-layout>
            </v-sheet>
          </div>
          <v-container v-if="!current_user" fluid>
            <!-- Hidden email field for password managers -->
            <v-text-field ref="username" v-model="invite.email" style="display: none" name="username" type="text" />

            <v-row v-if="invite.user_role == 'user'" justify="space-around">
              <v-text-field ref="name_given" v-model="name_given" :label="$t('common.first_name')" type="text" :error="name_given_error" />
              <v-text-field ref="name_family" v-model="name_family" :label="$t('common.last_name')" type="text" :error="name_family_error" />
            </v-row>
            <v-layout v-else justify-space-around>
              <v-flex xs2>
                <v-text-field ref="name_prefix" v-model="name_prefix" :label="$t('common.title')" type="text" />
              </v-flex>
              <v-flex xs4>
                <v-text-field ref="name_given" v-model="name_given" :label="$t('common.first_name')" type="text" :error="name_given_error" />
              </v-flex>
              <v-flex xs4>
                <v-text-field ref="name_family" v-model="name_family" :label="$t('common.last_name')" type="text" :error="name_family_error" />
              </v-flex>
              <v-flex v-if="invite.user_role != 'user'" xs2>
                <v-text-field ref="name_suffix" v-model="name_credentials" :label="$t('common.suffix')" type="text" />
              </v-flex>
            </v-layout>

            <DoublePassword
              ref="password"
              v-model="password"
              :label="$t('common.choose_a_password')"
              load-on-mount
              always-show-confirm
              @passwords-match="update_passwords_match"
            />
          </v-container>

          <p v-if="!tos_text" style="font-size: small; color: grey; margin-top: 5px; margin-bottom-5px" :html="$t('accept_invite.by_accepting_this_invitation', { terms_of_use: '<a href=\'/terms\' target=\'_blank\'>' + $t('common.terms_of_use') + '</a>' })"> 
          </p>
        </v-card-text>

        <v-card-actions v-if="!current_user">
          <v-container>
            <v-row justify="space-between" align="center">
              <v-alert v-if="error_message != ''" outlined :value="error_message != ''" type="error">{{ error_message }}</v-alert>

              <v-spacer />

              <v-btn v-if="invite && !invite.user_id" class="ml-1" color="primary" @click="accept_invite()">{{ $t("accept_invite.accept_invitation") }}</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import DoublePassword from "./DoublePassword";
import Login from "./Login";
import Vue from "vue";
import jQuery from "jquery";
import { logout_helpscout } from "../lib/helpscout";
import { mapState } from "vuex";

export default {
  components: { DoublePassword, Login },
  props: {
    invite_token: {
      type: String,
      required: true,
    },
    require_tos_scroll: {
      type: Boolean,
      default: false,
    },
    tos_text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      in_flight: false,
      passwords_match: false,
      password: "",
      token_first: "",
      token_second: "",
      name_prefix: "",
      name_given: "",
      name_family: "",
      name_credentials: "",
      name_given_error: false,
      name_family_error: false,
      local_invite_token: this.invite_token,
      invite: null,
      bad_invite_code_error: "",
      show_login: false,
      bad_invite_show_logout: false,
      error_message: "",
      logout_in_progress: false,
      show_scroll_tos: false,
      scroll_tos_at_bottom: false,
      scroll_tos_accepted: false,
    };
  },
  computed: { ...mapState(["current_user"]) },
  mounted() {
    let self = this;

    if (this.current_user && !this.local_invite_token) {
      this.link_user_invites();
    }
    this.fetch_invite();

    // Bind Enter Key to submit
    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 13 && self.invite_token) {
        self.submit();
      }
    });
  },
  methods: {
    decline_tos() {
      this.show_scroll_tos = false;
      this.scroll_tos_at_bottom = false;
      this.scroll_tos_accepted = false;
    },
    accept_tos() {
      // Send  "tos_accepted" activity to the backend when we are all done
      this.scroll_tos_accepted = true;
      this.accept_invite();
    },
    fetch_invite() {
      this.bad_invite_code_error = "";

      // Fetch the invite
      let self = this;
      if (this.local_invite_token) {
        // First check if the local_invite_token is well formed
        let valid_regex = /^[0-9A-Za-z][0-9A-Za-z][0-9A-Za-z][0-9A-Za-z]-[0-9A-Za-z][0-9A-Za-z][0-9A-Za-z][0-9A-Za-z]$/;
        if (!valid_regex.test(this.local_invite_token)) {
          self.bad_invite_code_error = this.$t("accept_invite.not_a_valid_code");
          self.local_invite_token = "";
          self.token_first = "";
          self.token_second = "";
          return;
        }

        self.show_login = false;
        self.in_flight = true;
        axios.get("/api/invite/by_token/" + self.local_invite_token).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            self.invite = resp.data;
            self.name_given = self.invite.name;
            self.name_family = self.invite.name_family;

            // If we are logged in, handle it in relation to the current user
            if (self.current_user) {
              // If the invite points to us, we're done
              if (self.invite.user_id == self.current_user.id) {
                messageBus.$emit("success", self.$t("accept_invite.invite_accepted"));
                self.$router.push("/app/clinic/" + self.invite.clinic_id);
                return;
              } else if (self.invite.user_id) {
                self.bad_invite_code_error = self.$t("accept_invite.invitation_code_already_been_used");
              } else {
                self.bad_invite_code_error = self.$t("accept_invite.this_is_a_valid_invitation_code_msg");
                self.bad_invite_show_logout = true;
              }
            }
            // We are anonymous
            else {
              if (self.invite.user_id) {
                if (self.invite.user_role == "user") {
                  // Do an auto-login with the invite token
                  self.in_flight = true;
                  axios
                    .post("/api/invite/token/login", {
                      token: self.local_invite_token,
                    })
                    .then(function (response) {
                      let resp = response.data;
                      if (resp.status == "success") {
                        self.in_flight = false;
                        window.location.pathname = "/app/clinic/" + self.invite.clinic_id;
                      } else {
                        self.bad_invite_code_error = self.$t("accept_invite.this_invitation_code_has_already_been_used_msg");
                        self.show_login = true;
                      }
                    });
                } else {
                  self.bad_invite_code_error = self.$t("accept_invite.this_invitation_code_has_already_been_used_msg");
                  self.show_login = true;
                }
              }
            }
          } else {
            if (resp.error_code == "InvalidInviteCodeFoundMatchingRefCode") {
              // Redirect to Clinic Sign-up
              window.top.location.href = "/signup?ref_code=" + self.local_invite_token + "#signup_anchor";
              return;
            } else if (resp.error_code == "InviteCodeNotFound" || resp.error_code == "InvalidInviteToken") {
              if (resp.error_code == "EntityNotFound") {
                self.bad_invite_code_error = self.$t("accept_invite.we_could_not_find_this_invitation_code");
              } else if (resp.error_code == "InvalidInviteToken") {
                self.bad_invite_code_error = self.$t("accept_invite.not_a_valid_code");
              } else {
                self.bad_invite_code_error = self.$t("accept_invite.unknown_error_validating_code");
              }

              self.local_invite_token = "";
              self.token_first = "";
              self.token_second = "";

              if (self.$refs.token_input_first) {
                self.$refs.token_input_first.focus();
              }
            } else {
              messageBus.$emit("api-error", resp);
            }
          }
        });
      }
    },
    link_user_invites() {
      axios.post("/api/invite/" + this.current_user.email + "/link");
    },
    token_first_input_keyup() {
      this.token_first = this.remove_invalid_chars(this.token_first);
      if (this.token_first.length == 4) {
        if (this.$refs.token_input_second) {
          this.$refs.token_input_second.focus();
        }
      }
    },
    token_second_input_keyup() {
      this.token_second = this.remove_invalid_chars(this.token_second);
      if (this.token_second.length == 4) {
        this.local_invite_token = this.token_first + "-" + this.token_second;
        this.fetch_invite();
      }
    },
    token_paste(e) {
      let token = e.clipboardData.getData("Text").trim();
      token = this.remove_invalid_chars(token);

      if (token.length == 9) {
        this.token_first = token.substring(0, 4);
        this.token_second = token.substring(5, 9);
      } else {
        this.token_first = token.substring(0, 4);
        this.token_second = token.substring(4, 8);
      }
      this.local_invite_token = this.token_first + "-" + this.token_second;

      if (this.local_invite_token.length == "9") {
        this.fetch_invite();
      }
    },
    remove_invalid_chars(token_part) {
      return token_part.replace(/[- ,."'_]/g, "");
    },
    update_passwords_match: function (event_value) {
      this.passwords_match = event_value;
    },
    submit: function () {
      this.accept_invite();
    },
    logout: function () {
      this.logout_in_progress = true;
      let redirect = "/app/code/";
      if (this.token_first && this.token_second) {
        redirect += this.token_first + "-" + this.token_second;
      }
      axios.post("/api/user/logout").then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          logout_helpscout();
          window.location.href = redirect;
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    accept_invite: function () {
      let self = this;

      // Reset error state
      this.name_given_error = false;
      this.name_family_error = false;
      this.error_message = "";

      if (!this.name_given) {
        this.error_message = this.$t("accept_invite.please_provide_first_name");
        this.name_given_error = true;
        this.$refs.name_given.focus();
        return;
      }
      if (!this.name_family) {
        this.error_message = this.$t("accept_invite.please_provide_last_name");
        this.name_family_error = true;
        this.$refs.name_family.focus();
        return;
      }
      if (!this.password) {
        this.error_message = this.$t("accept_invite.please_provide_password");
        if (this.$refs.password) {
          this.$refs.password.focus();
        }
        return;
      }
      if (!this.passwords_match) {
        this.error_message = this.$t("accept_invite.confirm_password_does_not_match_password");
        if (this.$refs.password) {
          this.$refs.password.focus_confirm();
        }
        return;
      }

      // If we need to accept TOS, do that next
      if (this.require_tos_scroll && !this.scroll_tos_accepted) {
        this.show_scroll_tos = true;

        let self = this;
        Vue.nextTick(() => {
          jQuery("#accept-invite-tos-textarea").scroll(function () {
            if (jQuery(this).scrollTop() + jQuery(this).height() >= jQuery(this)[0].scrollHeight - 4) {
              self.scroll_tos_at_bottom = true;
            }
          });
        });
        return;
      }

      self.in_flight = true;

      axios
        .post("/api/invite/" + self.invite.id + "/accept/" + self.local_invite_token, {
          email: self.invite.email,
          name_prefix: self.name_prefix,
          name_given: self.name_given,
          name_family: self.name_family,
          name_credentials: self.name_credentials,
          pass: self.password,
        })
        .then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            window.location.pathname = "/app/clinic/" + self.invite.clinic_id;
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
    },
  },
};
</script>

<style>
#opticalgym_accept_invite_modal .v-messages__message {
  line-height: normal;
}

@media only screen and (min-width: 700px) {
  #opticalgym_accept_invite_modal {
    width: 800px;
  }
}

@media only screen and (max-width: 420px) {
  #opticalgym_accept_invite_modal {
    width: 400px;
  }
}

.accept-invite-token-input input {
  text-transform: uppercase;
  margin-left: 12px;
}
</style>
