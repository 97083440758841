<template>
  <v-app>
    <div v-if="current_user" style="margin-top:100px">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex id="opticalgym_clinic_signup" sm8>
            <v-sheet elevation="2" style="padding:50px">
              <p>{{ $t("clinic_sign_up_route.looks_like_you_are_already_signed_up_and_logged_in", {email: current_user.email}) }}.</p>
              <p>{{ $t("clinic_sign_up_route.if_you_would_like_to_sign_up_new_business") }}</p>
              <ContactInfo style="margin-top: 50px; margin-left: 120px" :include_address="false" />
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <ClinicSignUp 
      v-else
      :collect_affiliation="false"
      :require_attestation="false"
      :collect_referral_code="false"
    />
  </v-app>
</template>

<script>
import ClinicSignUp from "../../shared/components/ClinicSignUp";
import ContactInfo from "../../shared/components/ContactInfo";

export default {
  components: {
    ClinicSignUp,
    ContactInfo
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user
    };
  }
};
</script>

<style>
</style>