<template>
  <v-autocomplete
    v-model="selectedLanguage"
    :label="$t('common.language')"
    :readonly="readonly"
    :outlined="outlined"
    :dense="dense"
    :items="languageOptions"
    @change="emitLanguageChange"
  ></v-autocomplete>
</template>

<script>
import languages from "../../../data/lang_codes.json";

export default {
  props: {
    value: String, // v-model from parent
    readonly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    limit_languages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLanguage: this.value,
    };
  },
  computed: {
    languageOptions() {
      return Object.keys(languages)
        .filter((key) => this.limit_languages.length === 0 || this.limit_languages.includes(key))
        .map((key) => {
          let text = languages[key].nativeName;
          if (languages[key].nativeName !== languages[key].name) {
            text += ` (${languages[key].name})`;
          }
          return { value: key, text };
        }).sort((a, b) => {
            // Selected language goes first, otherwise alphabetical
            if (a.value === this.selectedLanguage) {
                return -1;
            }
            else if (b.value === this.selectedLanguage) {
                return 1;
            }
            else return a.text.localeCompare(b.text)
        });
    },
  },
  watch: {
    value(newVal) {
      this.selectedLanguage = newVal;
    },
  },
  methods: {
    emitLanguageChange() {
      this.$emit("input", this.selectedLanguage);
    },
  },
};
</script>
